const isDev = process.env.NODE_ENV === 'development'
export const asyncRoute = {
    '2c77c15285ab48cba1203b027148d229': {
        path: '/work_management',
        name: 'work_management',
        title: '岗位管理',
        icon: '',
        component: () => import('@/views/power-management/work-management'),
        meta: {
            btnRights: {
                'add-options': 'wokerment/Add[添加岗位]',
                'update-options': 'wokerment/update[编辑及页面权限配置]',
                'delete-options': 'wokerment/delete[删除岗位]'
            }
        }
    },
    e54471e4f7ef4bec98bec7a94cba9226: {
        path: '/department_management',
        name: 'department_management',
        title: '部门管理',
        component: () =>
            import('@/views/power-management/department-management'),
        meta: {
            btnRights: {
                'add-options': 'department/add[创建部门]',
                'update-options': 'department/update[编辑部门]'
            }
        }
    },
    fb6b1533e639470591b592e69a49ad7f: {
        path: '/menu_management',
        name: 'menu_management',
        title: '菜单管理',
        component: () => import('@/views/power-management/menu-management')
    },
    '97abc5839dac4231bf5a45939202f447': {
        path: '/user_management',
        name: 'user_management',
        title: '用户管理',
        component: () => import('@/views/power-management/user-management'),
        meta: {
            btnRights: {
                'add-options': 'user/add[添加用户]',
                'workState-add-table':
                    'user/updateWorkState:post[添加在职状态]',
                'workState-update-table':
                    'user/updateWorkState:post[编辑在职状态]',
                'department-add-table': 'user/updateDepart:post[添加所属部门]',
                'department-update-table':
                    'user/updateDepart:put[编辑所属部门]',
                'role-add-table': 'user/updateRole:post[添加所属岗位]',
                'role-update-table': 'user/updateRole:put[添加所属岗位]',
                'datePower-add-table':
                    'user/updateDatePower:post[添加用户权限]',
                'datePower-update-table':
                    'user/updateDatePower:put[编辑用户权限]',
                'update-options': 'user/update[编辑用户]',
                'setPassword-options': 'user/setPassword[重置密码]',
                'log-options': 'user/getLogPageList[操作记录]'
            }
        }
    },
    '15853203bf63436abe705cb173361dd8': {
        path: '/do_management',
        name: 'do_management',
        title: '查询权限管理',
        component: () => import('@/views/power-management/do-management'),
        meta: {
            btnRights: {
                'add-options': 'dataPower/add[添加权限]',
                'update-options': 'dataPower/update[编辑及查询权限设置]',
                'delete-options': 'dataPower/delete[删除权限]'
            }
        }
    },
    '112cc8336d824269bc70da3552c9c8d3': {
        path: '/company_management',
        name: 'company_management',
        title: '公司管理',
        component: () => import('@/views/power-management/company-management'),
        meta: {
            btn: ['添加', '编辑']
        }
    },
    '7b88d82ed198499193f88b2c0e3db56f': {
        path: '/payment-channel',
        name: 'payment-channel',
        title: '支付方式设置',
        component: () => import('@/views/parameter-setting/payment-channel')
    },
    a9964259110e4419985673ae59e87d4f: {
        path: '/administrative_area',
        name: 'administrative_area',
        title: '行政区域管理',
        component: () =>
            import('@/views/parameter-setting/administrative-area'),
        meta: {
            btn: ['添加', '批量删除', '编辑', '删除', '下级地区']
        }
    },
    '8b1212dsdsa34zdxszdsda2fe': {
        path: '/pick_up_position',
        name: 'pick_up_position',
        title: '接送地点',
        component: () => import('@/views/parameter-setting/pick-up-position')
    },
    fb2f3572cebe43719d03209fb1e53339: {
        path: '/brand_set',
        name: 'brand_set',
        title: '品牌型号参数',
        component: () => import('@/views/parameter-setting/brand-set')
    },
    d65b14f0fce94f8f92043bc44b82fed5: {
        path: '/clue_from',
        name: 'clue_from',
        title: '线索来源',
        component: () => import('@/views/parameter-setting/clue-from')
    },
    f7a7ed8d081d4751a261d6e8d903efa9: {
        path: '/opposite_result',
        name: 'opposite_result',
        title: '跟进结果',
        component: () => import('@/views/parameter-setting/opposite-result')
    },

    bc8fa94d9a8348b1951b65ddbf53d109: {
        path: '/color_management',
        name: 'color_management',
        title: '颜色管理',
        component: () => import('@/views/parameter-setting/color-management')
    },
    '608013099cb64f8d8368959b0ea70b4a': {
        path: '/oli_point2',
        name: 'oli_point2',
        title: '加油点设置',
        component: () => import('@/views/parameter-setting/oli-point')
    },
    f2ca461db55f429192275c5a5d283a7f: {
        path: '/driver-setting',
        name: 'driver_setting',
        title: '驾校设置',
        component: () => import('@/views/parameter-setting/driver-setting'),
        meta: {
            btn: ['添加', '编辑', '删除']
        }
    },
    '5ece3e9d3d8d42e5970c225f524164bc': {
        path: '/sign-up-management',
        name: 'sign-up-management',
        title: '报名点',
        component: () => import('@/views/parameter-setting/sign-up-management')
    },
    e202a0c38e2c4cb9a63cff5c36028921: {
        path: '/insurance_company',
        name: 'insurance_company',
        title: '保险公司',
        component: () => import('@/views/parameter-setting/insurance-company')
    },
    '8b1212dsdsaas6f484a32fe': {
        path: '/training_subjects',
        name: 'training_subjects',
        title: '训练科目',
        component: () => import('@/views/parameter-setting/training-subjects')
    },
    '0af872dc0a994a79b349977f6e5d06a4': {
        path: '/examplace',
        name: 'examplace',
        title: '考试场地设置',
        component: () => import('@/views/parameter-setting/examplace')
    },
    f3f246e7c97142bebfd6c0547ff3a023: {
        path: '/user_sign',
        name: 'user_sign',
        title: '字员签字',
        component: () => import('@/views/parameter-setting/user-sign')
    },
    '46480fffc1b14846b90ccbf34e5e9bf1': {
        path: '/vehicle_loss_items',
        name: 'vehicle_loss_items',
        title: '车辆损耗项目',
        component: () => import('@/views/parameter-setting/vehicle-loss-items')
    },
    '14f187c6b197444581d1881836a00266': {
        path: '/repair_point',
        name: 'repair_point',
        title: '维修点设置',
        component: () => import('@/views/parameter-setting/repair-point')
    },
    '8b36dbcb14044a8e9bceba6f484a32fe': {
        path: '/sign-up-car-type',
        name: 'sign-up-car-type',
        title: '报考车型',
        component: () => import('@/views/parameter-setting/sign-up-car-type'),
        meta: {
            btn: ['添加', '批量删除', '编辑', '删除']
        }
    },
    '89257e1744074d1ab42b2a40515dbcc9': {
        path: '/pay_category2',
        name: 'pay_category2',
        title: '支出类目',
        component: () => import('@/views/parameter-setting/pay-category')
    },
    e9da213825a44c08bc97f1880ba9c0f2: {
        path: '/oli_data',
        name: 'oli_data',
        title: '油耗统计',
        meta: {
            btnRights: {
                'update-options': 'oilWaste/update[编辑]',
                'log-options': 'oilWaste/getLogPageList[记录]'
            }
        },
        component: () =>
            import('@/views/assets-management/loss-management/oliData')
    },
    '86bb1f41f9874a52aef6c6303d675fea': {
        path: '/maintain',
        name: 'maintain',
        title: '保养记录',
        meta: {
            btnRights: {
                'batch-add-options': 'car/BatchAddMaintenance[批量新增保养]',
                'add-options': 'car/AddMaintenance[新增保养]',
                'record-options': 'car/GetMaintenancePageList[保养记录]'
            }
        },
        component: () =>
            import('@/views/assets-management/loss-management/maintain')
    },
    '394ff2ac9b5a47cbbc3de7ab31135438': {
        path: '/car_damage',
        name: 'car_damage',
        title: '维修记录',
        meta: {
            btnRights: {
                'add-options': 'repairWaste/add[编辑油耗]',
                'update-options': 'repairWaste/update[编辑油耗]',
                'record-options': 'repairWaste/getList[车损记录]'
            }
        },
        component: () =>
            import('@/views/assets-management/loss-management/car-damage')
    },
    '7ec8f92047bf499e918d766aaad776a6': {
        path: '/violation',
        name: 'violation',
        title: '违章及事故',
        meta: {
            btnRights: {
                'danger-options': 'car/UpdateCarConfig[违章风险提示设置]',
                'peccancyNum-add-options': 'car/addPeccancy[添加违章]',
                'accidentNum-add-options': 'car/addAccident[添加事故]',
                'accidentNum-update-options': 'car/updateAccident[编辑事故]',
                'peccancyNum-update-options': 'car/updatePenccancy[编辑违章]',
                'accidentNum-delete-options': 'car/deleteAccident[删除事故]',
                'peccancyNum-delete-options': 'car/deletePeccancy[删除违章]'
            }
        },
        component: () =>
            import('@/views/assets-management/car-management/violation')
    },
    // '3f12ab5981da40f4aa5555f3420da5ad': {
    //     path: '/insurance_type',
    //     name: 'insurance-type',
    //     title: '补考保险种类',
    //     component: () => import('@/views/parameter-setting/insurance-type')
    // },
    '0mkf0a4508243e18be2d21e50c390eb': {
        path: '/test_class_type',
        name: 'test_class_type',
        title: '报考班型',
        component: () => import('@/views/parameter-setting/test-class-type'),
        meta: {
            btn: ['添加', '编辑', '删除']
        }
    },
    '34ea6efbbf394ec3ac6ecdb2baec469a': {
        path: '/pay_status',
        name: 'pay_status',
        title: '缴费状态设置',
        meta: {
            // btnRights: {
            //     'update-options': 'payState/update[编辑缴费状态]',
            // }
        },
        component: () => import('@/views/parameter-setting/pay-status')
    },
    '258820a4508243e18be2d21e50c390eb': {
        path: '/insurance_management',
        name: 'insurance_management',
        title: '保险管理',
        component: () =>
            import('@/views/parameter-setting/insurance-management')
    },
    f778405ee5c788235487512a79: {
        path: '/campus-management',
        name: 'campus-management',
        title: '校区管理',
        component: () => import('@/views/parameter-setting/campus-management'),
        meta: {
            btn: ['添加', '编辑', '配置权限', '删除']
        }
    },
    cc588dec3c80423ba803f63f52f74dae: {
        path: '/school_management',
        name: 'school_management',
        title: '院校管理',
        meta: {
            btnRights: {
                'update-options': 'payState/update[编辑缴费状态]'
            }
        },
        component: () =>
            import('@/views/parameter-setting/hight-school-management')
    },
    '5d838adb2a7e4144a55e500299100aef': {
        path: '/sale_source',
        name: 'sale_source',
        title: '销售来源设置',
        component: () => import('@/views/parameter-setting/sale-source'),
        meta: {
            btn: ['添加', '编辑', '删除']
        }
    },
    a6256c2fdae44af6bdeaf38efe6f3736: {
        path: '/collection-account',
        name: 'collection-account',
        title: '收款账户设置',
        component: () => import('@/views/parameter-setting/collection-account')
    },
    '29f78079773a427ea875de596dec7cac': {
        path: '/activity_type',
        name: 'activity_type',
        title: '活动类型',
        component: () => import('@/views/parameter-setting/activity-type')
    },
    be91a42cc4b0463bbc8e63812f51a52d: {
        path: '/class_pay_type2',
        name: 'class_pay_type2',
        title: '报班支付方式',
        component: () => import('@/views/parameter-setting/class-pay-type')
    },
    e7a78b04132c4bbda278329ed63fb9c5: {
        path: '/parameter_setting/subject-salary',
        name: 'subject-salary',
        title: '科目薪资设置',
        component: () => import('@/views/parameter-setting/subject-salary')
    },
    '10f915a449864ba5b0ce7c374d45f47a': {
        path: '/bank_management',
        name: 'bank_management',
        title: '银行管理',
        component: () => import('@/views/parameter-setting/bank-management')
    },
    d4ac2205922b4be59f0431f30a733f23: {
        path: '/country_management',
        name: 'country_management',
        title: '国家管理',
        component: () => import('@/views/parameter-setting/country-management')
    },
    '33bd6dc98fa2405995681e60a9cca85b': {
        path: '/section_four',
        name: 'section_four',
        title: '科四列表',
        meta: {
            btnRights: {
                // 'distribution-themeTwo-options': 'student/updateThemeTwoCoach[分配科二教练]',
                // 'distribution-themeThree-options': 'student/updateThemeThreeCoach[分配科三教练]',
                // 'distribution-train-options': 'student/updateTrainArea[分配培训校区]',
                'log-options': 'student/getLogPageList[考试记录]',
                'exam-update-table': 'Exam/update:put[编辑科四考试时间]',
                'exam-add-table': 'Exam/update:post[添加科四考试时间]'
            }
        },
        component: () =>
            import('@/views/train-management/speed-management/section-four')
    },
    '166f2b36947545b39a72b0d76b660cc3': {
        path: '/finish_school',
        name: 'finish_school',
        title: '已毕业',
        meta: {
            btnRights: {
                'exam-options': 'exam/getPageListByStudentId[考试记录]',
                'log-options': 'student/getLogPageList[操作记录]'
            }
        },
        component: () =>
            import('@/views/train-management/speed-management/finish-school')
    },
    c6d29198bdf44c88aa1740e753a421ff: {
        path: '/section_one_lib',
        name: 'section_one_lib',
        title: '科一列表',
        meta: {
            btnRights: {
                'distribution-themeTwo-options':
                    'student/updateThemeTwoCoach[分配科二教练]',
                'distribution-themeThree-options':
                    'student/updateThemeThreeCoach[分配科三教练]',
                'distribution-train-options':
                    'student/updateTrainArea[分配培训校区]',
                'log-options': 'student/getLogPageList[考试记录]',
                'train-update-table':
                    'student/updateTrainArea:put[编辑培训校区]',
                'train-add-table': 'student/updateTrainArea:post[添加培训校区]',
                'xueshi-update-table':
                    'student/updateTimeCode:post[更新科一学时状态]',
                'exam-update-table':
                    'student/updateWeipeiRemark:put[编辑考试日期]',
                'exam-add-table':
                    'student/updateWeipeiRemark:post[添加考试日期]',
                'themeTwo-add-table':
                    'student/updateThemeTwoCoach:post[添加科二教练]',
                'themeTwo-update-table':
                    'student/updateThemeTwoCoach:put[编辑科二教练]',
                'themeThree-update-table':
                    'student/updateThemeThreeCoach:put[编辑科三教练]',
                'payState-update-table': 'PayOrder/add:post[新增缴费]',
                'themeThree-add-table':
                    'student/updateThemeThreeCoach:post[添加科三教练]'
            }
        },
        component: () =>
            import('@/views/train-management/speed-management/section-one')
    },
    '87786fca15be4fc09d531a1661c7327e': {
        path: '/no-card-time-management',
        name: 'no-card-time-management',
        title: '未发卡学时管理',
        component: () => import('@/views/other/no-card-time-management')
    },
    '2a126a104f744c52bd406ab499bc380f': {
        path: '/section_two_three',
        name: 'section_two_three',
        title: '科二三列表',
        meta: {
            btnRights: {
                'distribution-themeTwo-options':
                    'student/updateThemeTwoCoach[分配科二教练]',
                'distribution-themeThree-options':
                    'student/updateThemeThreeCoach[分配科三教练]',
                'distribution-train-options':
                    'student/updateTrainArea[分配培训校区]',
                'log-options': 'student/getLogPageList[考试记录]',
                'train-update-table':
                    'student/updateTrainArea:put[编辑培训校区]',
                'train-add-table': 'student/updateTrainArea:post[添加培训校区]',
                'xueshi2-update-table':
                    'student/updateTimeCode:post[更新科二学时状态]',
                'xueshi3-update-table':
                    'student/updateTimeCode:post[更新科三学时状态]',
                'examTwo-update-table': 'Exam/update:put[编辑科二考试日期]',
                'examTwo-add-table': 'Exam/update:post[添加科二考试日期]',
                'examThree-add-table': 'Exam/update:post[添加科三考试日期]',
                'examThree-update-table': 'Exam/update:put[修改科三考试日期]',
                'themeTwo-add-table':
                    'student/updateThemeTwoCoach:post[添加科二教练]',
                'themeTwo-update-table':
                    'student/updateThemeTwoCoach:put[编辑科二教练]',
                'themeThree-update-table':
                    'student/updateThemeThreeCoach:put[编辑科三教练]',
                // 'payState-update-table': 'student/updateEnterClass:post[新增缴费]',
                'themeThree-add-table':
                    'student/updateThemeThreeCoach:post[添加科三教练]'
            }
        },
        component: () =>
            import(
                '@/views/train-management/speed-management/section-two-three'
            )
    },
    '44a39dd6ce4344c4b5d610573f1541b4': {
        path: '/make_exam',
        name: 'make_exam',
        title: '考试预约管理',
        component: () => import('@/views/other/exam-make')
    },
    '0020e34b580b4e749d1a5d9555c3cb50': {
        path: '/register',
        name: 'register',
        title: '报名登记',
        component: () =>
            import('@/views/recruit-management/sign-up-management/register'),
        meta: {
            btnRights: {
                'add-options': 'Student/add[保存 / 新增学员]'
            }
        }
    },
    '49e4099f4b4b44558900fd5fd19b0fe7': {
        path: '/leave_school',
        name: 'leave_school',
        title: '退学列表',
        meta: {
            btnRights: {
                'update-options': 'payOrder/updateDrop[编辑退学]',
                'cancel-options': 'payOrder/deleteDrop[取消退学]',
                'details-options':
                    'publicDataDictionary/getSelectItem[退款详情]',
                'confirm-options': 'payOrder/confrimDrop[确认退学]',
                'log-options': 'student/getLogPageList[操作记录]'
            }
        },
        component: () =>
            import(
                '@/views/recruit-management/sales-after-management/leave-school'
            )
    },
    de2afbf8d5714f8bb0dd83fc489015d7: {
        path: '/class_report',
        name: 'class_report',
        title: '报班管理',
        component: () => import('@/views/other/class-report')
    },
    '7af347edda754d94b98508021011d110': {
        path: '/customer_situation',
        name: 'customer_situation',
        title: '生源数据',
        component: () =>
            import(
                '@/views/recruit-management/sales-after-management/customer-situation'
            ),
        meta: {
            btnRights: {
                'train-add-table': 'Student/updateTrainArea:post[添加培训校区]',
                'train-update-table':
                    'Student/updateTrainArea:put[编辑培训校区]',
                'themeTwo-add-table':
                    'student/updateThemeTwoCoach:post[添加科二教练]',
                'themeTwo-update-table':
                    'student/updateThemeTwoCoach:put[编辑科二教练]',
                'themeThree-update-table':
                    'student/updateThemeThreeCoach:put[编辑科三教练]',
                'themeThree-add-table':
                    'student/updateThemeThreeCoach:post[添加科三教练]'
            }
        }
    },
    '7d54f74aa399479e9b31e9d2a4021657': {
        path: '/mini_program_check',
        name: 'mini-program-check',
        title: '小程序审核',
        component: () => import('@/views/other/mini-program-check')
    },
    // ----------这个是测试服的------------
    '428694e99127437abfa8a3653599bf35': {
        path: '/advance_input',
        name: 'advance_input',
        title: '预录入',
        component: () =>
            import(
                '@/views/recruit-management/sign-up-management/advance-input'
            )
    },
    //----------------------------
    e9bbebfdd692406eb25f69fde5bd5f20: {
        path: '/advance_input',
        name: 'advance_input',
        title: '预录入',
        component: () =>
            import(
                '@/views/recruit-management/sign-up-management/advance-input'
            )
    },
    //----------------------------
    '23413e273803403daf824adb7f8f7133': {
        path: '/clue_list',
        name: 'clue_list',
        title: '线索管理',
        component: () =>
            import('@/views/recruit-management/clue-management/clue-list')
    },
    e73d7a56b82741d9b66f21ecca6ac5af: {
        path: '/recruit',
        name: 'recruit',
        title: '报名列表(总)',
        meta: {
            keepAlive: true,
            btnRights: {
                'add-options': 'student/add[添加学员]',
                'distribution-themeTwo-options':
                    'student/updateThemeTwoCoach[分配科二教练]',
                'distribution-themeThree-options':
                    'student/updateFeedBackUser[分配科三教练]',
                'distribution-feedbackUser-options':
                    'student/updateThemeThreeCoach[分配客服专员]',
                'distribution-train-options':
                    'student/updateTrainArea[分配培训校区]',
                'distribution-addCard-options':
                    'student/updateMakeCard[批量制卡]',
                'distribution-class-options':
                    'student/UpdateEnterClass[批量报班]',
                'drop-options': 'student/wantDrop[退学]',
                'delete-options': 'student/delete[删除]',
                'log-options': 'student/getLogPageList[操作记录]',
                'sign-options': 'student/addFollowStudent[转入标记]',
                'weipei-options': 'student/outWeipei[转出委培]',
                'train-update-table':
                    'student/updateTrainArea:put[编辑培训校区]',
                'train-add-table': 'student/updateTrainArea:post[添加培训校区]',
                'card-update-table': 'student/updateMakeCard:put[编辑发卡驾校]',
                'card-add-table': 'student/updateMakeCard:post[添加发卡驾校]',
                'ce-update-table':
                    'student/updateCertificate:put[编辑报考车型]',
                'ce-add-table': 'student/updateCertificate:post[添加报考车型]',
                'ce-update-idCard': 'student/UpdateIdCard:post[编辑身份证号]',
                'enterClass-update-table':
                    'student/updateEnterClass:put[编辑报考班型]',
                'enterClass-add-table':
                    'student/updateEnterClass:post[添加报考班型]',
                'referenceOne-add-table':
                    'student/updateReference:post[添加招生人]',
                'referenceOne-update-table':
                    'student/updateReference:put[编辑招生人]',
                'feedBackUser-add-table':
                    'student/UpdateFeedBackUser:post[添加客服专员]',
                'feedBackUser-update-table':
                    'student/UpdateFeedBackUser:put[编辑客服专员]',
                // 'referenceTwo-update-table': 'student/UpdateSecondReference:put[编辑二级推荐人]',
                // 'referenceTwo-add-table': 'student/UpdateSecondReference:post[添加二级推荐人]',
                'payState-add-table': 'patState/add:post[添加缴费状态]',
                'remark-update-table': 'student/updateRemark:put[编辑备注]',
                'remark-add-table': 'student/updateRemark:post[添加备注]',
                'themeTwo-add-table':
                    'student/updateThemeTwoCoach:post[添加科二教练]',
                'themeTwo-update-table':
                    'student/updateThemeTwoCoach:put[编辑科二教练]',
                'themeThree-update-table':
                    'student/updateThemeThreeCoach:put[编辑科三教练]',
                'themeThree-add-table':
                    'student/updateThemeThreeCoach:post[添加科三教练]',
                'update-options': 'Student/update[编辑学员（简历中）]',
                'assign-students-two': 'Assign/students[分配学员二]',
                'assign-students-three': 'Assign/students[分配学员三]',
                'update-student-name': 'student/updateName[修改姓名]'
            }
        },
        component: () =>
            import('@/views/recruit-management/sign-up-management/recruit')
    },
    '59aa3d1b57664d03936e15c1ad728a84': {
        path: '/my_admissions',
        name: 'my_admissions',
        title: '我的招生',
        meta: {
            keepAlive: true,
            btnRights: {
                'add-options': 'student/add[添加学员]',
                'distribution-themeTwo-options':
                    'student/updateThemeTwoCoach[分配科二教练]',
                'distribution-themeThree-options':
                    'student/updateFeedBackUser[分配科三教练]',
                'distribution-feedbackUser-options':
                    'student/updateThemeThreeCoach[分配客服专员]',
                'distribution-train-options':
                    'student/updateTrainArea[分配培训校区]',
                'distribution-addCard-options':
                    'student/updateMakeCard[批量制卡]',
                'distribution-class-options':
                    'student/UpdateEnterClass[批量报班]',
                'drop-options': 'student/wantDrop[退学]',
                'delete-options': 'student/delete[删除]',
                'log-options': 'student/getLogPageList[操作记录]',
                'sign-options': 'student/addFollowStudent[转入标记]',
                'weipei-options': 'student/outWeipei[转出委培]',
                'train-update-table':
                    'student/updateTrainArea:put[编辑培训校区]',
                'train-add-table': 'student/updateTrainArea:post[添加培训校区]',
                'card-update-table': 'student/updateMakeCard:put[编辑发卡驾校]',
                'card-add-table': 'student/updateMakeCard:post[添加发卡驾校]',
                'ce-update-table':
                    'student/updateCertificate:put[编辑报考车型]',
                'ce-add-table': 'student/updateCertificate:post[添加报考车型]',
                'enterClass-update-table':
                    'student/updateEnterClass:put[编辑报考班型]',
                'enterClass-add-table':
                    'student/updateEnterClass:post[添加报考班型]',
                'referenceOne-add-table':
                    'student/updateReference:post[添加招生人]',
                'referenceOne-update-table':
                    'student/updateReference:put[编辑招生人]',
                'feedBackUser-add-table':
                    'student/UpdateFeedBackUser:post[添加客服专员]',
                'feedBackUser-update-table':
                    'student/UpdateFeedBackUser:put[编辑客服专员]',
                // 'referenceTwo-update-table': 'student/UpdateSecondReference:put[编辑二级推荐人]',
                // 'referenceTwo-add-table': 'student/UpdateSecondReference:post[添加二级推荐人]',
                'payState-add-table': 'patState/add:post[添加缴费状态]',
                'remark-update-table': 'student/updateRemark:put[编辑备注]',
                'remark-add-table': 'student/updateRemark:post[添加备注]',
                'themeTwo-add-table':
                    'student/updateThemeTwoCoach:post[添加科二教练]',
                'themeTwo-update-table':
                    'student/updateThemeTwoCoach:put[编辑科二教练]',
                'themeThree-update-table':
                    'student/updateThemeThreeCoach:put[编辑科三教练]',
                'themeThree-add-table':
                    'student/updateThemeThreeCoach:post[添加科三教练]',
                'update-options': 'Student/update[编辑学员（简历中）]',
                'assign-students-two': 'Assign/students[分配学员二]',
                'assign-students-three': 'Assign/students[分配学员三]'
            }
        },
        component: () =>
            import(
                '@/views/recruit-management/sign-up-management/my-admissions'
            )
    },
    a45ba814c47549458c83af695eeb1d94: {
        path: '/car_management',
        name: 'car_management',
        title: '车辆管理',
        meta: {
            btnRights: {
                'add-options': 'car/add[添加车辆]',
                'update-options': 'car/update[编辑车辆]',
                'record-options': 'car/getLogPageList[使用记录]',
                'danger-options': 'car/getCarConfig[风险提醒设置]',
                'remark-update-table':
                    'car/updateModelsRemark:put[编辑车型备注]',
                'remark-add-table': 'car/updateModelsRemark:post[添加车型备注]',
                'make-update-table': 'car/Update:post[更新车辆能源]',
                'state-update-table': 'car/Update:post[更新使用状态]',
                'do-update-table': 'car/Update:post[更新车辆用途]',
                'train-update-table': 'car/updateTrainArea:put[编辑在用校区]',
                'train-add-table': 'car/updateTrainArea:post[添加在用校区]',
                'user-add-table': 'car/updateUser:post[添加责任人]',
                'user-update-table': 'car/updateUser:put[编辑责任人]',
                'relation-update-table':
                    'car/updateRelationUser:put[编辑关联人]',
                'relation-add-table': 'car/updateRelationUser:post[添加关联人]'
            }
        },
        component: () =>
            import('@/views/assets-management/car-management/car-management')
    },
    e213e801f90344a48c1ed1030cb7b1e3: {
        path: '/check_insurance',
        name: 'check_insurance',
        title: '保险及检验',
        meta: {
            btnRights: {
                'record-options': 'car/getLogPageList[检包记录]',
                'mOTTime-update-table': 'car/updateMotTime:put[编辑年检有效期]',
                'mOTTime-add-table': 'car/updateMotTime:post[添加年检有效期]',
                'businessTime-update-table':
                    'car/updateBusinessTime:put[编辑营运检有效期]',
                'businessTime-add-table':
                    'car/updateBusinessTime:post[添加营运检有效期]',
                'mustEndTime-add-table':
                    'car/updateMustTime:post[添加交强险有效期]',
                'mustEndTime-update-table':
                    'car/updateMustTime:put[编辑交强险有效期]',
                'otherInsureShow-update-table':
                    'car/updateOtherTime:put[编辑商业险有效期]',
                'otherInsureShow-add-table':
                    'car/updateOtherTime:post[添加商业险有效期]',
                'otherInsure-del': 'car/DeleteLog:post[删除保险日志]'
            }
        },
        component: () =>
            import('@/views/assets-management/car-management/check-insurance')
    },
    '3e90db481179469291fbf1cc3f0242fc': {
        path: '/oil_waste_management',
        name: 'oil_waste_management',
        title: '油耗记录',
        meta: {
            btnRights: {
                'update-options': 'oilWaste/update[编辑油耗]',
                'delete-options': 'OilWaste/delete[删除油耗]',
                'record-options': 'oilWaste/getLogPageList[跟踪记录]'
            }
        },
        component: () =>
            import(
                '@/views/assets-management/loss-management/oil-waste-management'
            )
    },
    c4e6b7165be34130b65c3584edf358d1: {
        path: '/oil_card_management',
        name: 'oil_card_management',
        title: '油卡管理',
        meta: {
            btnRights: {
                'add-card-options': 'oilCard/add[添加油卡]',
                'charge-options': 'reCharge/add[充值]',
                'add-oil-options': 'oilWaste/add[新增油耗]',
                'sale-options': 'reCharge/getList[消费记录]',
                'add-quancun-options': 'oilCard/addQuancun[新增圈存]',
                'quancun-options': 'oilCard/getPageList[圈存记录]',
                'danger-options': 'oilWaste/getPerKMConfig[异常油耗设置]',
                'cark-update-table': 'oilCard/update:post[修改油卡编号]',
                'state-update-table': 'oilCard/Update:post[更新使用状态]',
                'userName-add-table': 'oilCard/update:post[添加责任人]',
                'userName-update-table': 'oilCard/update:put[编辑责任人]',
                'relationUserName-update-table':
                    'oilCard/update:put[编辑使用人]',
                'relationUserName-add-table': 'oilCard/update:post[添加使用人]',
                'createrUserName-update-table':
                    'oilCard/update:put[编辑办卡人]',
                'createrUserName-add-table': 'oilCard/update:post[添加办卡人]',
                'quancun-delete-table': 'oilCard/deleteQuanCun:post[删除圈存]'
            }
        },
        component: () =>
            import(
                '@/views/assets-management/car-management/oil-card-management'
            )
    },
    '450e1ad54c5b4bad95df463d142cb5f5': {
        path: '/demage_management',
        name: 'demage_management',
        title: '车损管理',
        component: () => import('@/views/other/damage-management')
    },
    cd92c63598564c92938303083e23557b: {
        path: '/exam_pay',
        name: 'exam_pay',
        title: '考试缴费',
        component: () =>
            import('@/views/finance-management/exam-pay-management/exam-pay'),
        meta: {
            btnRights: {
                'add-options': 'exam/addExamMoney[批量新增]',
                // 'update-options':'themeExamPay/update[缴费配置]',
                'log-options': 'exam.getPageListByStudentId[考试记录]'
            }
        }
    },
    f6b71306390d4e3dbf486272272941a8: {
        path: '/coach_level',
        name: 'coach_level',
        title: '教练等级',
        component: () => import('@/views/parameter-setting/coach-level')
    },

    '27cf232efd764fb384432007e133f9d8': {
        path: '/examination_hospital',
        name: 'examination_hospital',
        title: '体检医院',
        component: () =>
            import('@/views/parameter-setting/examination-hospital')
    },
    '40848098f3d047abaa581d9b42ff07a5': {
        path: '/deduction_subject',
        name: 'deduction_subject',
        title: '扣款项目',
        component: () => import('@/views/parameter-setting/deduction-subject')
    },
    a686c8ad9d25491b91bfada4e4472572: {
        path: '/examination-payment',
        name: 'examination-payment',
        title: '考试金额缴费',
        component: () => import('@/views/parameter-setting/examination-payment')
    },
    d87abadec9c94c1aa6664f0b6f03138: {
        path: '/answer_class',
        name: 'answer_class',
        title: '答题技巧分类',
        component: () => import('@/views/other/answer-class')
    },
    '65dddcb41ff54176b6ad6bbe5834b41e': {
        path: '/has_report_class',
        name: 'has_report_class',
        title: '已报班列表',
        component: () =>
            import(
                '@/views/exam-management/exam-before-manegement/has-report-class'
            ),
        meta: {
            btnRights: {
                'isUpCertificate-update-table':
                    'student/update:put[编辑是否增驾]',
                'isEnterClass-update-table':
                    'student/updateEnterClass:put[编辑报班状态]',
                'enterClassFinancialRemark-add-table':
                    'student/updateEnterClassRemark:post[新增报班财务备注]',
                'enterClassFinancialRemark-update-table':
                    'student/updateEnterClassRemark:put[编辑报班财务备注]',
                'add-remark-options':
                    'student/batchUpdateEnterClassFinancialRemark[批量编辑报班财务备注]',
                'log-options': 'student/getLogPageList[查询操作记录]'
            }
        }
    },
    '6c9d595952284bf6aa59303ea4822e66': {
        path: '/no_report_class',
        name: 'no_report_class',
        title: '未报班列表',
        component: () =>
            import(
                '@/views/exam-management/exam-before-manegement/no-report-class'
            ),
        meta: {
            btnRights: {
                'isUpCertificate-update-table':
                    'student/update:put[编辑是否增驾]',
                'materialCode-update-table':
                    'student/updateMaterialCode:put[编辑材料状态]',
                'materialDetail-update-table':
                    'student/updateMaterialType:put[编辑材料详情]',
                'isEnterClass-update-table':
                    'student/updateEnterClass:put[编辑报班状态]',
                'enterClassFinancialRemark-add-table':
                    'student/updateEnterClassFinancialRemark:post[新增报班财务备注]',
                'enterClassFinancialRemark-update-table':
                    'student/updateEnterClassFinancialRemark:put[修改报班财务备注]',
                'add-options': 'student/updateMaterialCode[批量编辑材料状态]',
                'add1-options': 'student/updateEnterClass[批量编辑报班状态]',
                'add-remark-options':
                    'student/batchUpdateEnterClassFinancialRemark[批量报班财务备注]',
                'payState-log-table': 'payOrder/getList[查询缴费状态]',
                'log-options': 'student/getLogPageList[查询操作记录]',
                'card-update-table': 'student/updateMakeCard:put[编辑发卡驾校]',
                'card-add-table': 'student/updateMakeCard:post[添加发卡驾校]'
            }
        }
    },
    '768887c9455841499d15107139b7e18f': {
        path: '/is_ordering',
        name: 'is_ordering',
        title: '预约中',
        component: () =>
            import('@/views/exam-management/appointment-search/is-ordering'),
        meta: {
            btnRights: {
                'update-options': 'exam/BatchUpdate[批量考试状态]',
                'log-options': 'exam/updateThemeTimeResult[考试记录]',
                'delete-options': 'exam/delete[删除]',
                'themeOneExamCode-update-table':
                    'exam/update:put[编辑考试状态]',
                'themeOneExamCode-add-table': 'exam/update:post[新增考试状态]'
            }
        }
    },
    '91bff8f1aae04c44a2b420b7e490485f': {
        path: '/order_success',
        name: 'order_success',
        title: '预约成功',
        component: () =>
            import('@/views/exam-management/appointment-search/order-success'),
        meta: {
            btnRights: {
                'update-options': 'exam/BatchUpdate[批量考试状态]',
                'log-options': 'exam/updateThemeTimeResult[考试记录]',
                'delete-options': 'exam/delete[删除]',
                'themeOneExamCode-update-table':
                    'exam/update:put[编辑考试状态]',
                'themeOneExamCode-add-table': 'exam/update:post[新增考试状态]'
            }
        }
    },
    fb6f06c3336848608b1e4be4144972f4: {
        path: '/order_success_publicity',
        name: 'order_success_publicity',
        title: '预约成功公示',
        component: () =>
            import(
                '@/views/exam-management/appointment-search/order-success-publicity'
            ),
        meta: {
            btnRights: {
                'update-options': 'exam/BatchUpdate[批量考试状态]',
                'log-options': 'exam/updateThemeTimeResult[考试记录]',
                'delete-options': 'exam/delete[删除]',
                'themeOneExamCode-update-table':
                    'exam/update:put[编辑考试状态]',
                'themeOneExamCode-add-table': 'exam/update:post[新增考试状态]'
            }
        }
    },
    '673bfb6cc4924715a6cae05e4f4541e7': {
        path: '/account_appointment',
        name: 'account_appointment',
        title: '预约统计',
        component: () =>
            import(
                '@/views/exam-management/appointment-search/account-appointment'
            )
    },
    fa7a0b24cf2f43898212048e0d5a4513: {
        path: '/cancel_order',
        name: 'cancel_order',
        title: '取消预约',
        component: () =>
            import('@/views/exam-management/appointment-search/cancel-order'),
        meta: {
            btnRights: {
                'update-options': 'exam/BatchUpdate[批量考试状态]',
                'log-options': 'exam/updateThemeTimeResult[考试记录]',
                'delete-options': 'exam/delete[删除]'
            }
        }
    },
    dad8a53277d44c429e57e4f942bb7100: {
        path: '/fail_analysis',
        name: 'fail_analysis',
        title: '挂科分析',
        component: () =>
            import('@/views/exam-management/exam-after-search/fail-analysis')
    },
    '3e711f8d6f82447e96029f3a92cd3242': {
        path: '/achievement_search',
        name: 'achievement_search',
        title: '成绩查询',
        component: () =>
            import(
                '@/views/exam-management/exam-after-search/achievement-search'
            ),
        meta: {
            btnRights: {
                'update-options': 'Exam/BatchUpdate[批量考试状态]',
                'log-options': 'Exam/updateThemeTimeResult[考试记录]'
            }
        }
    },
    c3c20b73a20e4a49978c4fc4a4a18b84: {
        path: '/pass_rate_search',
        name: 'pass_rate_search',
        title: '合格率查询',
        component: () =>
            import('@/views/exam-management/exam-after-search/pass-rate-search')
    },
    '80e57e587c8843e294bd6388a016d4be': {
        path: '/pass_rate_account',
        name: 'pass_rate_account',
        title: '通过率统计',
        component: () =>
            import(
                '@/views/exam-management/exam-after-search/pass-rate-account'
            )
    },
    // '6c9d595952284bf6aa59303ea4822e66': {
    //     path: '/no_report_management',
    //     name: 'no-report-management',
    //     title: '未报班管理',
    //     component: () => import('@/views/exam-management/exam-before-manegement/no-report-management')
    // },
    e1c0e8106c6e42e2868e306e08a05dce: {
        path: '/simulation_one',
        name: 'simulation_one',
        title: '科一模拟',
        component: () =>
            import(
                '@/views/exam-management/exam-before-manegement/simulation-one'
            )
    },
    // '65dddcb41ff54176b6ad6bbe5834b41e': {
    //     path: '/report_list',
    //     name: 'report-list',
    //     title: '已报班列表',
    //     component: () => import('@/views/exam-management/exam-before-manegement/report-list')
    // },
    f50b98a3cdb34f5aae6603397762ebe9: {
        path: '/my_exam',
        name: 'my_exam',
        title: '我的考试',
        component: () => import('@/views/cocah-server/my-work/my-exam')
    },
    faa51caf876e4f0eb590de391fff8d0f: {
        path: '/my_train',
        name: 'my_train',
        title: '我的培训',
        component: () => import('@/views/cocah-server/my-work/my-train')
    },
    '03435b93b081468994002d5948e3327c': {
        path: '/my_no_pay',
        name: 'my_no_pay',
        title: '我的未缴',
        component: () => import('@/views/cocah-server/my-work/my-no-pay')
    },
    '410a2711024e40a2abbe4b5f3f66b6ea': {
        path: '/my_car',
        name: 'my_car',
        title: '我的车辆',
        component: () => import('@/views/cocah-server/my-work/my-car')
    },
    '1c10d6a99d8e421481e9e4bffd28a9b4': {
        path: '/my_loss',
        name: 'my_loss',
        title: '我的损耗',
        component: () => import('@/views/cocah-server/my-work/my-loss')
    },
    bb320d29a5ab4afb805356a4f8f144cd: {
        path: '/my_wages',
        name: 'my_wages',
        title: '我的工资',
        component: () => import('@/views/cocah-server/my-work/my-wages')
    },
    '7fc2fa4a2d664e088bf0fc9541bb84d5': {
        path: '/my_students',
        name: 'my_students',
        title: '我的学员',
        component: () => import('@/views/cocah-server/my-work/my-students')
    },
    '9af6b6f749604bfd8f9890ab80434d7e': {
        path: '/allocated_recommend',
        name: 'allocated_recommend',
        title: '待分配(推荐)',
        component: () => import('@/views/cocah-server/allocated-recommend')
    },
    da8870e378bc4825ae1542ca37aa6b5a: {
        path: '/allocated_principal3',
        name: 'allocated_principal3',
        title: '待分配三(校长)',
        meta: {
            btnRights: {
                'theme-two-allocate-code':
                    '/Student/AllocateThemeTwoCoach[分配科二教练]',
                'theme-three-allocate-code':
                    '/Student/AllocateThemeThreeCoach[分配科三教练]',
                'log-options': 'student/getLogPageList[操作记录]'
            }
        },
        component: () => import('@/views/cocah-server/allocated-principal3')
    },
    '5e02250c812b4fff93c7b13f39735107': {
        path: '/allocated_principal',
        name: 'allocated_principal',
        title: '待分配二(校长)',
        meta: {
            btnRights: {
                'theme-two-allocate-code':
                    '/Student/AllocateThemeTwoCoach[分配科二教练]',
                'theme-three-allocate-code':
                    '/Student/AllocateThemeThreeCoach[分配科三教练]',
                'log-options': 'student/getLogPageList[操作记录]'
            }
        },
        component: () => import('@/views/cocah-server/allocated-principal')
    },
    '3b7f1b21309e421292622ee3fb88dba8': {
        path: '/returning_students',
        name: 'returning_students',
        title: '退回学员',
        meta: {
            btnRights: {
                'theme-two-allocate-code':
                    '/Student/AllocateThemeTwoCoach[分配科二教练]',
                'theme-three-allocate-code':
                    '/Student/AllocateThemeThreeCoach[分配科三教练]',
                'log-options': 'student/getLogPageList[操作记录]'
            }
        },
        component: () => import('@/views/cocah-server/returning-students')
    },
    e00b88b0cf5c4101939d5003271c2c8c: {
        path: '/risk_operation',
        name: 'risk_operation',
        title: '风险操作',
        meta: {
            // btnRights: {
            //     'theme-two-allocate-code':
            //         '/Student/AllocateThemeTwoCoach[分配科二教练]',
            //     'theme-three-allocate-code':
            //         '/Student/AllocateThemeThreeCoach[分配科三教练]',
            //     'log-options': 'student/getLogPageList[操作记录]'
            // }
        },
        component: () =>
            import('@/views/cocah-server/operation-record/risk-operation')
    },
    '99784f9f51ac4174ad5637a440f2404b': {
        path: '/confirmed',
        name: 'confirmed',
        title: '待确认',
        meta: {
            btnRights: {
                'confirm-two-trainees': '/Student/ConfirmCoach[确认科二学员]',
                'confirm-three-trainees': '/Student/ConfirmCoach[确认科三学员]'
            }
        },
        component: () => import('@/views/cocah-server/confirmed')
    },
    '19df48060caa49e3b6b9031fcdd9a8ff': {
        path: '/entrust_list',
        name: 'entrust_list',
        title: '委培列表1',
        meta: {
            btnRights: {
                'distribution-themeTwo-options':
                    'student/updateThemeTwoCoach[分配科二教练]',
                'distribution-themeThree-options':
                    'student/updateThemeThreeCoach[分配科三教练]',
                'distribution-train-options':
                    'student/updateTrainArea[分配培训校区]',
                'sign-options': 'student/addFollowStudent[转入标记]',
                'weipei-options': 'student/outWeipei[转出委培]',
                'train-update-table':
                    'student/updateTrainArea:put[编辑培训校区]',
                'train-add-table': 'student/updateTrainArea:post[添加培训校区]',
                'weipei-update-table':
                    'student/updateEnterClass:post[更新委培状态]',
                'remark-update-table':
                    'student/updateWeipeiRemark:put[编辑委培备注]',
                'remark-add-table':
                    'student/updateWeipeiRemark:post[添加委培备注]',
                'themeTwo-add-table':
                    'student/updateThemeTwoCoach:post[添加科二教练]',
                'themeTwo-update-table':
                    'student/updateThemeTwoCoach:put[编辑科二教练]',
                'themeThree-update-table':
                    'student/updateThemeThreeCoach:put[编辑科三教练]',
                'themeThree-add-table':
                    'student/updateThemeThreeCoach:post[添加科三教练]',
                'feedBackUser-add-table':
                    'student/UpdateFeedBackUser:post[添加客服专员]',
                'feedBackUser-update-table':
                    'student/UpdateFeedBackUser:put[编辑客服专员]'
            }
        },
        component: () =>
            import('@/views/recruit-management/sign-up-management/entrust-list')
    },
    '859927f63db2463faedfa3777c3d8a6a': {
        path: '/entrust_list_two',
        name: 'entrust_list_two',
        title: '委培列表2',
        meta: {
            btnRights: {
                'sign-options': 'student/outWeipei[转出委培]',
                'tag-options': 'Student/addFollowStudent[添加标记]',
                'train-update-table':
                    'student/updateTrainArea:put[编辑培训校区]',
                'train-add-table': 'student/updateTrainArea:post[添加培训校区]',
                'weipei-update-table':
                    'student/updateEnterClass:post[更新委培状态]',
                'remark-update-table':
                    'student/updateWeipeiRemark:put[编辑委培备注]',
                'remark-add-table':
                    'student/updateWeipeiRemark:post[添加委培备注]',
                'themeTwo-add-table':
                    'student/updateThemeTwoCoach:post[添加科二教练]',
                'themeTwo-update-table':
                    'student/updateThemeTwoCoach:put[编辑科二教练]',
                'themeThree-update-table':
                    'student/updateThemeThreeCoach:put[编辑科三教练]',
                'themeThree-add-table':
                    'student/updateThemeThreeCoach:post[添加科三教练]',
                'feedBackUser-add-table':
                    'student/UpdateFeedBackUser:post[添加客服专员]',
                'feedBackUser-update-table':
                    'student/UpdateFeedBackUser:put[编辑客服专员]'
            }
        },
        component: () =>
            import(
                '@/views/recruit-management/sign-up-management/entrust-list2'
            )
    },
    '6c608523cc9e47aba9a32c2c13ffa65d': {
        path: '/change_class_list',
        name: 'change_class_list',
        title: '换班列表',
        component: () =>
            import(
                '@/views/recruit-management/sign-up-management/change-class-list'
            )
    },
    '3cd99fcaced84062b8311c4dc5ebed51': {
        path: '/is_delete',
        name: 'is_delete',
        title: '已删除',
        meta: {
            btnRights: {
                'log-options': 'exam/getPageListByStudentId[考试记录]',
                'restart-options': 'student/restore[恢复数据]'
            }
        },
        component: () =>
            import('@/views/recruit-management/sign-up-management/is-delete')
    },
    af967bf66978487d93cf046f027a5229: {
        path: '/sign_list',
        name: 'sign_list',
        title: '标记列表(我的)',
        meta: {
            btnRights: {
                'sign-options': 'student/addFollowStudent[转入标记]',
                'marker-update-table': 'student/updateMarker:put[编辑标记备注]',
                'marker-add-table': 'student/updateMarker:post[添加标记备注]',
                'themeTwo-add-table':
                    'student/updateThemeTwoCoach:post[添加科二教练]',
                'themeTwo-update-table':
                    'student/updateThemeTwoCoach:put[编辑科二教练]',
                'themeThree-update-table':
                    'student/updateThemeThreeCoach:put[编辑科三教练]',
                'themeThree-add-table':
                    'student/updateThemeThreeCoach:post[添加科三教练]',
                'feedBackUser-add-table':
                    'student/UpdateFeedBackUser:post[添加客服专员]',
                'feedBackUser-update-table':
                    'student/UpdateFeedBackUser:put[编辑客服专员]'
            }
        },
        component: () =>
            import('@/views/recruit-management/sign-up-management/sign-list')
    },
    '5486c1bbdcd64fd19b3726f06ccb08df': {
        path: '/contract_list',
        name: 'contract_list',
        title: '合同列表',
        component: () =>
            import(
                '@/views/recruit-management/contract-management/contract-list'
            )
    },
    '7baac93f0790430d8dd8f7c6a7fe5225': {
        path: '/receipt_list',
        name: 'receipt_list',
        title: '收据列表',
        component: () =>
            import(
                '@/views/recruit-management/contract-management/receipt-list'
            )
    },
    '0e92b06c5ada4d7f84fc4a05c8776d5a': {
        path: '/track_complaint',
        name: 'track_complaint',
        title: '客诉管理',
        meta: {
            btnRights: {
                'distribution-train-options':
                    'student/updateTrainArea[分配培训校区]',
                'distribution-themeTwo-options':
                    'student/updateThemeTwoCoach[分配科二教练]',
                'distribution-themeThree-options':
                    'student/updateThemeThreeCoach[分配科三教练]',
                'train-update-table':
                    'student/updateTrainArea:put[修改培训校区]',
                'train-add-table': 'student/updateTrainArea:post[添加培训校区]',
                'userName-update-table':
                    'feedback/updateUser:put[修改客服专员]',
                'userName-add-table': 'feedback/updateUser:post[添加客服专员]',
                'code-update-table': 'feedback/updateCode:put[修改客服状态]',
                'code-add-table': 'feedback/updateCode:post[添加客服状态]',
                'remark-update-table': 'student/updateRemark:put[编辑备注]',
                'customRemark-update-table': 'feedback/update[编辑客诉解决]',
                'remark-add-table': 'student/updateRemark:post[添加备注]',
                'themeTwo-add-table':
                    'student/updateThemeTwoCoach:post[添加科二教练]',
                'themeTwo-update-table':
                    'student/updateThemeTwoCoach:put[编辑科二教练]',
                'themeThree-update-table':
                    'student/updateThemeThreeCoach:put[编辑科三教练]',
                'themeThree-add-table':
                    'student/updateThemeThreeCoach:post[添加科三教练]',
                'log-options': 'student/addFollowStudent[客诉记录]',
                'sign-options': 'student/addFollowStudent[转入标记]',
                'weipei-options': 'student/outWeipei[转出委培]',
                'delete-options': 'feedback/delete[删除]'
            }
        },
        component: () =>
            import(
                '@/views/recruit-management/sales-after-management/track-complaint'
            )
    },
    '463a385f27084cc3bb28d43d83b3d1d2': {
        path: '/born_account',
        name: 'born_account',
        title: '生源统计',
        component: () =>
            import(
                '@/views/recruit-management/sales-after-management/born-account'
            )
    },
    '2054e271741d48c281e144d1f11c8300': {
        path: '/sale_account',
        name: 'sale_account',
        title: '销售统计',
        component: () =>
            import('@/views/recruit-management/sale-management/sale-account')
    },
    ca13904046a142b4840c72bcf7216daa: {
        path: '/commission_list',
        name: 'commission_list',
        title: '提成列表',
        meta: {
            btnRights: {
                'batchMoney-options':
                    'student/batchUpdateReferenceMoney[批量提成金额]',
                'moneyCode-options':
                    'student/updateReferenceMoneyCode[批量发放状态]',
                'ageMax-options': 'student/UpdateAgeMax[超龄设置]',
                'code-options': 'student/updateReferenceMoneyRule[待发放提示]',
                'log-options': 'student/getLogPageList[操作记录]',
                'train-update-table':
                    'student/updateTrainArea:put[修改培训校区]',
                'train-add-table': 'student/updateTrainArea:post[添加培训校区]',
                'money-add-table':
                    'student/updateReferenceMoney:post[添加提成金额]',
                'money-update-table':
                    'student/updateReferenceMoney:put[修改提成金额]',
                'state-update-table':
                    'student/updateReferenceMoneyCode:post[编辑发放状态]',
                'referenceRemark-add-table':
                    'student/updateReferenceRemark:post[添加发放备注]',
                'referenceRemark-update-table':
                    'student/updateReferenceRemark:put[修改发放备注]'
            }
        },
        component: () =>
            import('@/views/recruit-management/sale-management/commission-list')
    },
    '0a2f9d8bdef44ea084668d32da3f0fd5': {
        path: '/commission_sign',
        name: 'commission_sign',
        title: '提成登记',
        meta: {
            btnRights: {
                'add-options': 'commission/add[新增提成设置]',
                'update-options': 'commission/update[编辑提成设置]',
                'delete-options': 'commission/delete[删除提成设置]',
                'content-update-table':
                    'commission/updateCommissionContent:put[修改提成内容]',
                'content-add-table':
                    'commission/updateCommissionContent:post[添加提成内容]',
                'money-add-table':
                    'commission/updateBonusContent:post[添加奖金内容]',
                'money-update-table':
                    'commission/updateBonusContent:put[修改奖金内容]',
                'other-update-table':
                    'commission/updateOtherContent:put[编辑其他内容]',
                'other-add-table':
                    'commission/updateOtherContent:post[添加其他内容]'
            }
        },
        component: () =>
            import('@/views/recruit-management/sale-management/commission-sign')
    },
    '9ff5fe2db0aa43d3ae0a639ee0d1ca45': {
        path: '/class_management',
        name: 'class_management',
        title: '约课管理',
        component: () => import('@/views/train-management/class-management')
    },
    '75bdd6db26a14b9aa6d91caf53b89a9e': {
        path: '/three_hours',
        name: 'three_hours',
        title: '科三学时',
        component: () =>
            import('@/views/train-management/hours-management/three-hours')
    },
    cfd4f7fa7457437692068e23c045b046: {
        path: '/two_hours',
        name: 'two_hours',
        title: '科二学时',
        component: () =>
            import('@/views/train-management/hours-management/two-hours')
    },
    c08fe37f8cc046398f2036eecdccbbad: {
        path: '/one_hours',
        name: 'one_hours',
        title: '科一学时',
        component: () =>
            import('@/views/train-management/hours-management/one-hours')
    },
    '7c23fdd0933b49fb8baba7b8257518f6': {
        path: '/place_management',
        name: 'place_management',
        title: '场地管理',
        component: () => import('@/views/train-management/place-management')
    },
    bdf934be0db54730bbee7fc712dbb6d0: {
        path: '/in_lib_count',
        name: 'in_lib_count',
        title: '在库统计',
        component: () =>
            import('@/views/train-management/speed-management/in-lib-count')
    },

    ae9a41d60c554acfa92faa80ff19eea1: {
        path: '/speed_search',
        name: 'speed_search',
        title: '进度查询',
        meta: {
            btnRights: {
                'themeTwo-options':
                    'student/updateThemeTwoCoach[批量分配科二教练]',
                'themeThree-options':
                    'student/updateThemeThreeCoach[批量分配科三教练]',
                'train-options': 'student/updateTrainArea[批量分配校区]',
                'log-options': 'student/getLogPageList[考试记录]',
                'train-update-table':
                    'student/updateTrainArea:put[修改培训校区]',
                'train-add-table': 'student/updateTrainArea:post[添加培训校区]',
                'themeTwo-add-table':
                    'student/updateThemeTwoCoach:post[添加科二教练]',
                'themeTwo-update-table':
                    'student/updateThemeTwoCoach:put[修改科二教练]',
                'themeThree-update-table':
                    'student/updateThemeThreeCoach:put[编辑科三教练]',
                'themeThree-add-table':
                    'student/updateThemeThreeCoach:post[添加科三教练]'
            }
        },
        component: () =>
            import('@/views/train-management/speed-management/speed-search')
    },
    '5cdf671317764e848797d016324f1bf4': {
        path: '/to_be_allocated',
        name: 'to_be_allocated',
        title: '待分配',
        component: () =>
            import('@/views/train-management/speed-management/to-be-allocated')
    },
    '8123f1c312124c24880240896139f11b': {
        path: '/allocated_recommend',
        name: 'allocated_recommend',
        title: '待分配(推荐)',
        component: () => import('@/views/cocah-server/allocated-recommend')
    },
    '56517852508b49bd9b02738a6da8fbe3': {
        path: '/allocated_principal',
        name: 'allocated_principal',
        title: '待分配(校长)',
        meta: {
            btnRights: {
                'theme-two-allocate-code':
                    '/Student/AllocateThemeTwoCoach[分配科二教练]',
                'theme-three-allocate-code':
                    '/Student/AllocateThemeThreeCoach[分配科三教练]',
                'log-options': 'student/getLogPageList[操作记录]'
            }
        },
        component: () => import('@/views/cocah-server/allocated-principal')
    },
    // '166f2b36947545b39a72b0d76b660cc3': {
    //     path: '/ready_graduation',
    //     name: 'ready-graduation',
    //     title: '已毕业',
    //     component: () => import('@/views/train-management/speed-management/ready-graduation')
    // },
    fbc01b7006d746ba8a182dc481edb3a2: {
        path: '/overdue_list',
        name: 'overdue_list',
        title: '过期列表',
        meta: {
            btnRights: {
                'time-options': 'student/updateTimeOutDate[延长有效期]',
                'drop-options': 'student/wantDrop[退学]',
                'delete-options': 'student/delete[删除]',
                'exam-options': 'exam/getPageListByStudentId[考试记录]',
                'log-options': 'student/getLogPageList[操作记录]',
                'remark-update-table': 'student/updateRemark:put[修改到期备注]',
                'remark-add-table': 'student/updateRemark:post[添加到期备注]'
            }
        },
        component: () =>
            import('@/views/train-management/speed-management/overdue-list')
    },
    // 'a45ba814c47549458c83af695eeb1d94': {
    //     path: '/car_list',
    //     name: 'car-list',
    //     title: '车辆列表',
    //     component: () => import('@/views/assets-management/car-management/car-list')
    // },
    // 'e213e801f90344a48c1ed1030cb7b1e3': {
    //     path: '/insurance_and_test',
    //     name: 'insurance-and-test',
    //     title: '保险及检验',
    //     component: () => import('@/views/assets-management/car-management/insurance-and-test')
    // },
    // '7ec8f92047bf499e918d766aaad776a6': {
    //     path: '/violation_and_accident',
    //     name: 'violation-and-accident',
    //     title: '违章及事故',
    //     component: () => import('@/views/assets-management/car-management/violation-and-accident')
    // },
    // 'c4e6b7165be34130b65c3584edf358d1': {
    //     path: '/oil_card_config',
    //     name: 'oil-card-config',
    //     title: '油卡配置',
    //     component: () => import('@/views/assets-management/car-management/oil-card-config')
    // },
    '22077f3ee8eb4ed68846ff1267402b12': {
        path: '/oli-point',
        name: 'oli-point',
        title: '油站管理',
        // component: () => import('@/views/assets-management/car-management/oil-station-config')
        component: () => import('@/views/parameter-setting/oli-point')
    },
    // '3e90db481179469291fbf1cc3f0242fc': {
    //     path: '/fuel_consumption_record',
    //     name: 'fuel-consumption-record',
    //     title: '油耗记录',
    //     component: () => import('@/views/assets-management/loss-management/fuel-consumption-record')
    // },
    // 'e9da213825a44c08bc97f1880ba9c0f2': {
    //     path: '/fuel_consumption_count',
    //     name: 'fuel-consumption-count',
    //     title: '油耗记录',
    //     component: () => import('@/views/assets-management/loss-management/fuel-consumption-count')
    // },
    '503487348c5446c189e6ffc561e2c0c8': {
        path: '/power_consumption_record',
        name: 'power_consumption_record',
        title: '电耗记录',
        component: () =>
            import(
                '@/views/assets-management/loss-management/power-consumption-record'
            )
    },
    c1d1e85fa6ae4720bd0e120cd69a3e81: {
        path: '/power_consumption_count',
        name: 'power_consumption_count',
        title: '电耗统计',
        component: () =>
            import(
                '@/views/assets-management/loss-management/power-consumption-count'
            )
    },
    // '394ff2ac9b5a47cbbc3de7ab31135438': {
    //     path: '/car_damage_record',
    //     name: 'car-damage-record',
    //     title: '车损记录',
    //     component: () => import('@/views/assets-management/loss-management/car-damage-record')
    // },
    '18adda296a14410695e17e58667e29eb': {
        path: '/car_damage_count',
        name: 'car_damage_count',
        title: '车损统计',
        component: () =>
            import('@/views/assets-management/loss-management/car-damage-count')
    },
    a48f6ff21a0942be80e6b0afd16a49d0: {
        path: '/car_damage_sign',
        name: 'car-damage-sign',
        title: '车损设置',
        component: () =>
            import('@/views/assets-management/loss-management/car-damage-sign')
        // component: () => import('@/views/assets-management/loss-management/car-damage-sign')
    },
    // '86bb1f41f9874a52aef6c6303d675fea': {
    //     path: '/maintain_date',
    //     name: 'maintain-date',
    //     title: '保养设置',
    //     component: () => import('@/views/assets-management/loss-management/maintain-date')
    // },
    '46e74cf44d81488781aed953783a56ac': {
        path: '/teaching_supplies',
        name: 'teaching-supplies',
        title: '教学用品',
        component: () => import('@/views/assets-management/teaching-supplies')
    },
    '4b944bb91807404b951d67045b100108': {
        path: '/collection_search',
        name: 'collection_search',
        title: '收款查询',
        component: () =>
            import(
                '@/views/finance-management/tuition-management/collection-search'
            ),
        meta: {
            btnRights: {
                'add-options': 'payOrder/add[新增缴费]',
                'delete-options': 'payOrder/delete[删除]',
                'isConfirm-update-table': '.payOrder/confirm[确认状态]',
                'remark-update-table':
                    'payOrder/UpdatePayRemark:post[编辑备注]',
                'remark-add-table': 'payOrder/UpdatePayRemark:post[添加备注]'
            }
        }
    },
    '643d7cf3a043464b9bb62ddf7da895e7': {
        path: '/collection_count',
        name: 'collection_count',
        title: '收款统计',
        component: () =>
            import(
                '@/views/finance-management/tuition-management/collection-count'
            )
    },
    '9b894da122874f138954c0e70776211e': {
        path: '/pay_abnormal',
        name: 'pay_abnormal',
        title: '缴费异常',
        component: () =>
            import(
                '@/views/finance-management/tuition-management/pay-abnormal'
            ),
        meta: {
            btnRights: {
                'config-options': 'payState/updatePay[缴费异常配置]',
                'payLog-options': 'student/getLogPageList[缴费记录]',
                'log-options': 'exam/getPageListByStudentId[考试记录]',
                'remark-update-table': 'student/updateRemark:put[备注]',
                'remark-add-table': 'student/updateRemark:post[备注]'
            }
        }
    },
    '2163b024d55c402190fd17c79d133cf0': {
        path: '/pay_config',
        name: 'pay-config',
        title: '缴费配置',
        component: () =>
            import('@/views/finance-management/tuition-management/pay-config')
    },
    '56ab437c6bf6415b955ef49ca37bd20b': {
        path: '/ready_sign_list',
        name: 'ready_sign_list',
        title: '预报名列表',
        component: () =>
            import(
                '@/views/finance-management/tuition-management/ready-sign-list'
            ),
        meta: {
            btnRights: {
                'tag-options': 'companyDataDictionary/getList[备注标签设置]',
                'pay-options': 'payOrder/add[缴费]',
                'out-options': 'payOrder/updateDrop[退学]',
                'follow-options': 'student/addFollowStudent[标记]',
                'trainAreaName-update-table':
                    'student/UpdateTrainArea:put[修改培训校区]',
                'trainAreaName-add-table':
                    'student/UpdateTrainArea:post[新增培训校区]',
                'feedBackUser-add-table':
                    'student/UpdateFeedBackUser:post[添加客服专员]',
                'feedBackUser-update-table':
                    'student/UpdateFeedBackUser:put[编辑客服专员]',
                'themeTwoCoachName-update-table':
                    'student/updateThemeTwoCoach:put[修改科二教练]',
                'themeTwoCoachName-add-table':
                    'student/updateThemeTwoCoach:post[新增科二教练]',
                'studentTag-update-table': 'student/UpdateTag:put[修改备注]',
                'studentTag-add-table': 'student/UpdateTag:post[新增备注]'
            }
        }
    },
    '78b6909f4dcd4080aa537cc7eea6d292': {
        path: '/tuition_count',
        name: 'tuition_count',
        title: '学费统计',
        component: () =>
            import(
                '@/views/finance-management/tuition-management/tuition-count'
            )
    },
    // 'cd92c63598564c92938303083e23557b': {
    //     path: '/exam_pay',
    //     name: 'exam-pay-manamenet',
    //     title: '考试缴费',
    //     component: () => import('@/views/finance-management/exam-pay-manamenet-management/exam-pay-manamenet')
    // },
    // 'ca1c759bfdfe452a939507bdf8293d0e': {
    //     path: '/make_insurance',
    //     name: 'make-insurance',
    //     title: '补考保险',
    //     component: () => import('@/views/finance-management/exam-pay-manamenet-management/make-insurance')
    // },
    fd9f78fb162a4ce99794127fbe0e719e: {
        path: '/insurance_config',
        name: 'insurance_config',
        title: '保险设置',
        component: () =>
            import(
                '@/views/finance-management/exam-pay-management/insurance-config'
            ),
        meta: {
            btnRights: {
                'add-options': 'examInsure/add[添加]',
                'update-options': 'examInsure/update[编辑]',
                'isPublic-update-table': 'examInsure/updateIsPublic[是否公开]'
            }
        }
    },

    // '99cfcb91a7ba4d36b3d087e113792610': {
    //     path: '/identity',
    //     name: 'identity',
    //     title: '身份信息',
    //     component: () => import('@/views/parameter-setting/identity')
    // },
    ca1c759bfdfe452a939507bdf8293d0e: {
        path: '/make_up_insurance',
        name: 'make_up_insurance',
        title: '补考保险',
        component: () =>
            import(
                '@/views/finance-management/exam-pay-management/make-up-insurance'
            ),
        meta: {
            btnRights: {
                'look-options': 'examInsure/see[查看保险内容]',
                'examInsureName-update-table':
                    'student/updateStudentExamInsure:put[修改补考保险]',
                'examInsureName-add-table':
                    'student/updateStudentExamInsure:post[新增补考保险]'
            }
        }
    },
    '0ea576229a4f436b95617c65067b879f': {
        path: '/car_finance',
        name: 'car_finance',
        title: '车辆财务',
        component: () =>
            import(
                '@/views/finance-management/enterprise-operation/car-finance'
            )
    },
    f552bac4507a4b92865a00ad3ec2f004: {
        path: '/insurances_foun',
        name: 'insurances_foun',
        title: '车辆财务',
        component: () =>
            import(
                '@/views/finance-management/enterprise-operation/insurances-foun'
            )
    },
    '9a0dcb6a211d41538e190e6414a4425d': {
        path: '/journal_book',
        name: 'journal_book',
        title: '日记账本',
        component: () =>
            import(
                '@/views/finance-management/enterprise-operation/journal-book'
            )
    },
    '838abc643ea74503b3ffb406644acbf4': {
        path: '/section_config',
        name: 'section_config',
        title: '科目配置',
        component: () =>
            import(
                '@/views/finance-management/enterprise-operation/section-config'
            )
    },
    '0c97a97aadaa436a860504ee55b91097': {
        path: '/management_count',
        name: 'management_count',
        title: '经营统计',
        component: () =>
            import(
                '@/views/finance-management/enterprise-operation/management-count'
            )
    },
    b4c3a2231f084f01bcb41cb382cfb7b1: {
        path: '/department_work',
        name: 'department_work',
        title: '部门及岗位',
        component: () =>
            import('@/views/hr-management/member-management/department-work')
    },
    '0ecadd08ff094e4e99b1971546ed4799': {
        path: '/member_management',
        name: 'member-management',
        title: '成员管理',
        component: () =>
            import('@/views/hr-management/member-management/member-management')
    },
    eb922fb991e341ac855e9ac0df483cf7: {
        path: '/coach_management',
        name: 'coach_management',
        title: '教练列表',
        component: () =>
            import('@/views/hr-management/member-management/coach-management2'),
        meta: {
            btnRights: {
                'add-options': 'user/add[添加教练]',
                'update-options': 'user/update[编辑教练]',
                'log-options': 'user/getLogPageList[使用记录]',
                'workState-add-table':
                    'user/updateWorkState:post[添加在职状态]',
                'driverShopName-add-table':
                    'user/updateTrainArea:post[添加带教驾校]',
                'driverShopName-update-table':
                    'user/updateTrainArea:post[编辑带教驾校]',
                'trainAreaName-add-table':
                    'user/updateTrainArea:post[添加带教校区]',
                'trainAreaName-update-table':
                    'user/updateTrainArea:post[编辑带教校区]',
                'certificateName-add-table':
                    'user/updateCertificate:post[添加带教车型]',
                'certificateName-update-table':
                    'user/updateCertificate:post[编辑带教车型]',
                'enterClassName-add-table':
                    'user/updateEnterClass:post[添加带教班型]',
                'enterClassName-update-table':
                    'user/updateEnterClass:post[编辑带教班型]',
                'workState-update-table':
                    'user/updateWorkState:put[编辑在职状态]',
                'roleName-add-table': 'user/updateRole:post[添加所属岗位]',
                'roleName-update-table': 'user/updateRole:put[编辑所属岗位]',
                'departmentName-add-table':
                    'user/updateDepart:post[添加所属部门]',
                'departmentName-update-table':
                    'user/updateDepart:put[编辑所属部门]',
                'level-add-table': 'user/updateLevelId:post[添加岗位等级]',
                'level-update-table': 'user/updateLevelId:put[编辑岗位等级]'
                // 'dataPowerNamsStr-add-table': 'user/updateDatePower:post[添加用户权限]',
                // 'dataPowerNamsStr-update-table': 'user/updateDatePower:put[编辑用户权限]',
            }
        }
    },
    '35729991358749f0a12302ec4080d963': {
        path: '/math_management',
        name: 'math_management',
        title: '教学组管理',
        component: () =>
            import('@/views/hr-management/member-management/math-management'),
        meta: {
            btnRights: {
                'add-options': 'coachGroup/add[新增教学组]',
                'update-options': 'coachGroup/update[编辑教学组]',
                'delete-options': 'coachGroup/delete[删除教学组]',
                'log-options': 'coachGroup/GetLogPageList[操作记录]'
            }
        }
    },
    '309b11c4516345ee8e0a91cb0f090cba': {
        path: '/refence_management',
        name: 'refence-management',
        title: '招生人管理',
        component: () =>
            import('@/views/hr-management/member-management/refence-management')
    },
    '6833adb086db4b7791123793167fcd1a': {
        path: '/admin_management',
        name: 'admin-management',
        title: '行政人员管理',
        component: () =>
            import('@/views/hr-management/member-management/admin-management')
    },
    bfaa4a74271d4c039128f8b6075713e0: {
        path: '/wages_list',
        name: 'wages_list',
        title: '工资总表',
        component: () =>
            import('@/views/hr-management/wages-management/wages-list')
    },
    '94d127ecb81c4ad3adf0b260fde134b9': {
        path: '/teacher_wages',
        name: 'teacher_wages',
        title: '工资总表',
        component: () =>
            import('@/views/hr-management/wages-management/teacher-wages')
    },
    '60d1ce3146de4ccba12bc82cf5b9dfe1': {
        path: '/sale_wages',
        name: 'sale_wages',
        title: '销售工资',
        component: () =>
            import('@/views/hr-management/wages-management/sale-wages')
    },
    '5588c84583bc4138b4991029aeae7e79': {
        path: '/admin_wages',
        name: 'admin_wages',
        title: '行政工资',
        component: () =>
            import('@/views/hr-management/wages-management/admin-wages')
    },
    '4c98cc8d54564654b3e92d9017ac1652': {
        path: '/awages_count',
        name: 'awages_count',
        title: '工资统计',
        component: () =>
            import('@/views/finance-management/wages-management/wages-count')
    },
    '434da96541ed4d5db2de82f4474743fe': {
        path: '/awages_number',
        name: 'awages_number',
        title: '工资账号',
        component: () =>
            import('@/views/finance-management/wages-management/wages-number')
    },
    c40442a7c6e5428197f719b643ce9642: {
        path: '/awages_config',
        name: 'awages_config',
        title: '工资配置',
        component: () =>
            import('@/views/finance-management/wages-management/wages-config')
    },
    // 财务-企业-支出类目
    c6b9d6a038894490b641030135f04f09: {
        path: '/pay_category',
        name: 'pay_category',
        title: '支出类目',
        component: () => import('@/views/parameter-setting/pay-category'),
        meta: {
            btnRights: {
                'add-options': 'expendThingCategory/add[添加]',
                'update-options': 'expendThingCategory/update[编辑]',
                'delete-options': 'expendThingCategory/delete[删除]'
            }
        }
    },
    // 财务-企业-支出类目
    e7d356f2da6b422e88d0407b065a403a: {
        path: '/income_category',
        name: 'income_category',
        title: '收入类目',
        component: () => import('@/views/parameter-setting/income-category'),
        meta: {
            btnRights: {
                'add-options': 'expendThingCategory/add[添加]',
                'update-options': 'expendThingCategory/update[编辑]'
            }
        }
    },
    // 财务-企业-收支情况
    '7b2bdf74564040bba5c493655534871d': {
        path: '/financial_performance',
        name: 'financial_performance',
        title: '收入情况',
        component: () =>
            import(
                '@/views/finance-management/payable-management/financial-performance'
            ),
        meta: {
            btnRights: {
                'add-options': 'expendThingCategory/add[添加]',
                'update-options': 'expendThingCategory/update[编辑]'
            }
        }
    },
    '3c211e796d6f4678bfc56758e1d6dd0d': {
        path: '/expenditure_performance',
        name: 'expenditure_performance',
        title: '支出情况',
        component: () =>
            import(
                '@/views/finance-management/payable-management/expenditure-performance'
            ),
        meta: {
            btnRights: {
                'add-options': 'expendThingCategory/add[添加]',
                'update-options': 'expendThingCategory/update[编辑]'
            }
        }
    },
    // 资产-损耗-维修点设置
    '315420715cb24b60af944065110b3f74': {
        path: '/repair_point2',
        name: 'repair-point2',
        title: '维修点设置',
        component: () => import('@/views/parameter-setting/repair-point')
    },
    // 财务-学费管理-支付渠道设置
    ebff637e97534f0a99e40af669d22234: {
        path: '/payment_channel',
        name: 'payment_channel',
        title: '支付方式设置',
        component: () => import('@/views/parameter-setting/payment-channel')
    },
    // 财务管理-财务设置-实缴类别
    '9c1fb3b534014190abc726157c760c8b': {
        path: '/paid_category',
        name: 'paid_category',
        title: '实缴类别',
        component: () => import('@/views/parameter-setting/paid-category')
    },
    // 人事管理-工资管理-科目薪资设置
    fdc908599b674515a235c57c7751ede5: {
        path: '/subject_salary',
        name: 'subject_salary',
        title: '科目薪资设置',
        component: () => import('@/views/parameter-setting/subject-salary')
    },
    // 招生管理-设置-校区管理
    '928392a995c74003bc9b255160b6c7db': {
        path: '/campus_management',
        name: 'campus_management',
        title: '校区管理',
        meta: {
            btnRights: {
                'add-options': 'trainArea/add[新增校区]',
                'update-options': 'trainArea/update[编辑校区]',
                'delete-options': 'trainArea/delete[删除校区]',
                // 'power-options': 'trainArea/delete[配置权限]',
                'ce-update-table':
                    'trainArea/updateCommissionContent:put[修改报考车型]',
                'ce-add-table':
                    'trainArea/updateCommissionContent:post[添加报考车型]',
                'enterClass-add-table':
                    'trainArea/updateBonusContent:post[添加报考班型]',
                'enterClass-update-table':
                    'trainArea/updateBonusContent:put[修改报考班型]'
            }
        },
        component: () => import('@/views/parameter-setting/campus-management')
    },
    // 招生管理-设置-报名点
    e18193d6676d4f869eb17a06c7e0dfa5: {
        path: '/sign_up_management',
        name: 'sign_up_management',
        title: '报名点',
        meta: {
            btnRights: {
                'add-options': 'companyDataDictionary/add[新增校区]',
                'update-options': 'companyDataDictionary/update[编辑校区]',
                'delete-options': 'companyDataDictionary/delete[删除校区]'
            }
        },
        component: () => import('@/views/parameter-setting/sign-up-management')
    },
    // 招生管理-设置-报考班型
    e7eff18cb9fa454480deff5fd2843a35: {
        path: '/test_class_type',
        name: 'test_class_type2',
        title: '报考班型',
        meta: {
            btnRights: {
                'add-options': 'enterClass/add[新增报考班型]',
                'update-options': 'enterClass/update[编辑报考班型]',
                'delete-options': 'enterClass/delete[删除报考班型]'
            }
        },
        component: () => import('@/views/parameter-setting/test-class-type')
    },
    //招生管理-招生设置-体检医院
    '3704df9c431841c2843d89005b23b922': {
        path: '/examination_hospital2',
        name: 'examination_hospital2',
        title: '体检医院',
        component: () =>
            import('@/views/parameter-setting/examination-hospital')
    },
    //招生管理-招生设置-院校管理
    '79d8e8367b4d463482e89b1c16eb6315': {
        path: '/school_management2',
        name: 'school_management2',
        title: '院校管理',
        component: () =>
            import('@/views/parameter-setting/hight-school-management')
    },
    //招生管理-招生设置-缴费状态设置
    '588efca14fba40e3b78da434ee6ddefc': {
        path: '/pay_status2',
        name: 'pay_status2',
        title: '缴费状态设置',
        // meta: {
        //     btnRights: {
        //         'update-options': 'payState/update[编辑缴费状态]',
        //     }
        // },
        component: () => import('@/views/parameter-setting/pay-status')
    },
    // 招生管理-招生设置-退学扣款项目
    b22e93961c4c43de90a9f8d2156a7186: {
        path: '/deduction_subject2',
        name: 'deduction_subject2',
        title: '退学扣款项目',
        component: () => import('@/views/parameter-setting/deduction-subject')
    },
    // 招生管理-招生设置-退学银行管理
    '36c7b38fab8743b08dbf3f1da661580c': {
        path: '/bank-management2',
        name: 'bank-management2',
        title: '退学银行管理',
        component: () => import('@/views/parameter-setting/bank-management')
    },
    // 财务管理-财务设置-考试费管理
    bb475b890b8e42e181edebd2142b8010: {
        path: '/exam_pay_management',
        name: 'exam_pay_management',
        title: '考试缴费金额',
        component: () =>
            import('@/views/finance-management/set-finance/exam-pay-management')
    },
    // 财务管理-财务设置-正考费用
    '4d335c8a84124f319079eba672e5edf0': {
        path: '/exam_pay_first_management',
        name: 'exam_pay_first_management',
        title: '考试缴费金额',
        component: () =>
            import(
                '@/views/finance-management/set-finance/exam-first-pay-management'
            )
    },

    // 招生管理-招生设置-支付方式设置
    '45e983647eaa422dbe2f7f6a924f83fa': {
        path: '/class_pay_type',
        name: 'class_pay_type',
        title: '支付方式设置',
        component: () => import('@/views/parameter-setting/class-pay-type')
    },
    // 财务管理-学费管理-收款方式设置
    f1880db284ed4bb59276e40c34e40220: {
        path: '/collection_account',
        name: 'collection_account',
        title: '收款方式设置',
        component: () => import('@/views/parameter-setting/collection-account')
    },
    //资产管理-资产设置-维修项目设置
    b3761be4265d49178ec47af71e5058fd: {
        path: '/vehicle_loss_items2',
        name: 'vehicle_loss_items2',
        title: '维修项目设置',
        meta: {
            btnRights: {
                'add-options': 'repairCategory/add[新增维修项目]',
                'update-options': 'repairCategory/update[编辑维修项目]'
            }
        },
        component: () => import('@/views/parameter-setting/vehicle-loss-items')
    },
    '4ea29e225acb4335bce89c200d03998a': {
        path: '/identity',
        name: 'identity',
        title: '身份信息',
        component: () => import('@/views/parameter-setting/identity')
    },
    //招生管理-招生设置-驾校管理
    '2ff2b8b878e24c40827d6381ed7bfc7c': {
        path: '/driver_management',
        name: 'driver_management',
        title: '驾校管理',
        meta: {
            btnRights: {
                'update-options': 'company/updateCompanyDriverShop[编辑]',
                'create-url-options': '[生成链接]'
            }
        },
        component: () =>
            import('@/views/recruit-management/setting/driver-management')
    },
    //招生改了-招生设置-报考车型
    f1dd28d399894cb1acebdde7a0369b9b: {
        path: '/sign_up_car_type2',
        name: 'sign_up_car_type2',
        title: '报考车型',
        component: () => import('@/views/parameter-setting/sign-up-car-type')
    },
    //招生管理-招生设置-驾校管理
    d0df8a8f816048e79bc96a39edf5e10a: {
        path: '/exam_error_log',
        name: 'exam_error_log',
        title: '查询异常',
        component: () =>
            import('@/views/exam-management/appointment-search/exam-error-log')
    },
    //招生管理-招生设置-驾校管理
    '822fec0507c84641b6c6e9ac2c8a113a': {
        path: '/affiliated_students',
        name: 'affiliated_students',
        title: '挂靠学员',
        meta: {
            keepAlive: true,
            btnRights: {
                'add-options': 'student/add[添加学员]',
                'distribution-themeTwo-options':
                    'student/updateThemeTwoCoach[分配科二教练]',
                'distribution-themeThree-options':
                    'student/updateFeedBackUser[分配科三教练]',
                'distribution-feedbackUser-options':
                    'student/updateThemeThreeCoach[分配客服专员]',
                'distribution-train-options':
                    'student/updateTrainArea[分配培训校区]',
                'distribution-addCard-options':
                    'student/updateMakeCard[批量制卡]',
                'distribution-class-options':
                    'student/UpdateEnterClass[批量报班]',
                'drop-options': 'student/wantDrop[退学]',
                'delete-options': 'student/delete[删除]',
                'log-options': 'student/getLogPageList[操作记录]',
                'sign-options': 'student/addFollowStudent[转入标记]',
                'weipei-options': 'student/outWeipei[转出委培]',
                'train-update-table':
                    'student/updateTrainArea:put[编辑培训校区]',
                'train-add-table': 'student/updateTrainArea:post[添加培训校区]',
                'card-update-table': 'student/updateMakeCard:put[编辑发卡驾校]',
                'card-add-table': 'student/updateMakeCard:post[添加发卡驾校]',
                'ce-update-table':
                    'student/updateCertificate:put[编辑报考车型]',
                'ce-add-table': 'student/updateCertificate:post[添加报考车型]',
                'enterClass-update-table':
                    'student/updateEnterClass:put[编辑报考班型]',
                'enterClass-add-table':
                    'student/updateEnterClass:post[添加报考班型]',
                'referenceOne-add-table':
                    'student/updateReference:post[添加招生人]',
                'referenceOne-update-table':
                    'student/updateReference:put[编辑招生人]',
                'feedBackUser-add-table':
                    'student/UpdateFeedBackUser:post[添加客服专员]',
                'feedBackUser-update-table':
                    'student/UpdateFeedBackUser:put[编辑客服专员]',
                // 'referenceTwo-update-table': 'student/UpdateSecondReference:put[编辑二级推荐人]',
                // 'referenceTwo-add-table': 'student/UpdateSecondReference:post[添加二级推荐人]',
                'payState-add-table': 'patState/add:post[添加缴费状态]',
                'remark-update-table': 'student/updateRemark:put[编辑备注]',
                'remark-add-table': 'student/updateRemark:post[添加备注]',
                'themeTwo-add-table':
                    'student/updateThemeTwoCoach:post[添加科二教练]',
                'themeTwo-update-table':
                    'student/updateThemeTwoCoach:put[编辑科二教练]',
                'themeThree-update-table':
                    'student/updateThemeThreeCoach:put[编辑科三教练]',
                'themeThree-add-table':
                    'student/updateThemeThreeCoach:post[添加科三教练]',
                'update-options': 'Student/update[编辑学员（简历中）]',
                'assign-students-two': 'Assign/students[分配学员二]',
                'assign-students-three': 'Assign/students[分配学员三]',
                'examTwo-update-table': 'Exam/update:put[编辑科二考试日期]',
                'examTwo-add-table': 'Exam/update:post[添加科二考试日期]',
                'examThree-add-table': 'Exam/update:post[添加科三考试日期]',
                'examThree-update-table': 'Exam/update:put[修改科三考试日期]'
            }
        },
        component: () =>
            import(
                '@/views/recruit-management/driving-school-students/affiliated-students'
            )
    },
    '53fe62a323ec4bba92c3a42a34d4a856': {
        path: '/affiliated_delete',
        name: 'affiliated_delete',
        title: '挂靠删除',
        meta: {
            keepAlive: true,
            btnRights: {
                'add-options': 'student/add[添加学员]',
                'distribution-themeTwo-options':
                    'student/updateThemeTwoCoach[分配科二教练]',
                'distribution-themeThree-options':
                    'student/updateFeedBackUser[分配科三教练]',
                'distribution-feedbackUser-options':
                    'student/updateThemeThreeCoach[分配客服专员]',
                'distribution-train-options':
                    'student/updateTrainArea[分配培训校区]',
                'distribution-addCard-options':
                    'student/updateMakeCard[批量制卡]',
                'distribution-class-options':
                    'student/UpdateEnterClass[批量报班]',
                'drop-options': 'student/wantDrop[退学]',
                'delete-options': 'student/delete[删除]',
                'log-options': 'student/getLogPageList[操作记录]',
                'sign-options': 'student/addFollowStudent[转入标记]',
                'weipei-options': 'student/outWeipei[转出委培]',
                'train-update-table':
                    'student/updateTrainArea:put[编辑培训校区]',
                'train-add-table': 'student/updateTrainArea:post[添加培训校区]',
                'card-update-table': 'student/updateMakeCard:put[编辑发卡驾校]',
                'card-add-table': 'student/updateMakeCard:post[添加发卡驾校]',
                'ce-update-table':
                    'student/updateCertificate:put[编辑报考车型]',
                'ce-add-table': 'student/updateCertificate:post[添加报考车型]',
                'enterClass-update-table':
                    'student/updateEnterClass:put[编辑报考班型]',
                'enterClass-add-table':
                    'student/updateEnterClass:post[添加报考班型]',
                'referenceOne-add-table':
                    'student/updateReference:post[添加招生人]',
                'referenceOne-update-table':
                    'student/updateReference:put[编辑招生人]',
                'feedBackUser-add-table':
                    'student/UpdateFeedBackUser:post[添加客服专员]',
                'feedBackUser-update-table':
                    'student/UpdateFeedBackUser:put[编辑客服专员]',
                // 'referenceTwo-update-table': 'student/UpdateSecondReference:put[编辑二级推荐人]',
                // 'referenceTwo-add-table': 'student/UpdateSecondReference:post[添加二级推荐人]',
                'payState-add-table': 'patState/add:post[添加缴费状态]',
                'remark-update-table': 'student/updateRemark:put[编辑备注]',
                'remark-add-table': 'student/updateRemark:post[添加备注]',
                'themeTwo-add-table':
                    'student/updateThemeTwoCoach:post[添加科二教练]',
                'themeTwo-update-table':
                    'student/updateThemeTwoCoach:put[编辑科二教练]',
                'themeThree-update-table':
                    'student/updateThemeThreeCoach:put[编辑科三教练]',
                'themeThree-add-table':
                    'student/updateThemeThreeCoach:post[添加科三教练]',
                'update-options': 'Student/update[编辑学员（简历中）]',
                'assign-students-two': 'Assign/students[分配学员二]',
                'assign-students-three': 'Assign/students[分配学员三]',
                'examTwo-update-table': 'Exam/update:put[编辑科二考试日期]',
                'examTwo-add-table': 'Exam/update:post[添加科二考试日期]',
                'examThree-add-table': 'Exam/update:post[添加科三考试日期]',
                'examThree-update-table': 'Exam/update:put[修改科三考试日期]'
            }
        },
        component: () =>
            import(
                '@/views/recruit-management/driving-school-students/affiliated-delete'
            )
    },
    //财务管理-工资管理-培训工资
    '724bd70cd0e24caf97a071fbf7b23db0': {
        path: '/training_salary',
        name: 'training_salary',
        title: '培训工资',
        component: () =>
            import(
                '@/views/finance-management/wages-management/training-salary'
            ),
        meta: {
            btnRights: {
                'log-options': 'Exam/see_log[考试记录]',
                'add-training-salary-options':
                    'Exam/SalaryMoneyBatchUpdate[批量培训费用]',
                'add-remark-options':
                    'Exam/SalaryRemarkBatchUpdate[批量发放备注]'
            }
        }
    },
    //财务管理-应缴管理-应缴记录
    '8a71ff36c48c493d9849a580d368e76b': {
        path: '/payable_records',
        name: 'payable_records',
        title: '实缴学员',
        component: () =>
            import(
                '@/views/finance-management/payable-management/payable-records'
            ),
        meta: {
            btnRights: {
                'payableRecords-add-options': 'Collect/Add[新增实缴]',
                'log-options': 'student/getLogPageList[操作记录]',
                'payableRecords-records-options':
                    'Collect/GetPageList[实缴记录]',
                'update-collect-money':
                    'Student/UpdateCollectMoney[修改实缴总价]',
                'update-management-money': 'Expend/Add[修改管理费]',
                'update-entrust-money': 'Expend/Add[修改培训费]',
                'update-marketing-money': 'Expend/Add[修改营销费]',
                'update-other-money': 'Expend/Add[修改营销费]',
                'payableRecords-update-options': 'Collect/Update[编辑实缴]',
                'payableRecords-del-options': '.Collect/Delete[删除实缴]',
                'enterClass-update-table':
                    'student/updateEnterClass:put[编辑报考班型]',
                'enterClass-add-table':
                    'student/updateEnterClass:post[添加报考班型]',
                'card-update-table': 'student/updateMakeCard:put[编辑发卡驾校]',
                'card-add-table': 'student/updateMakeCard:post[添加发卡驾校]'
            }
        }
    },
    //财务管理-应缴管理-实缴记录
    d0d7068e081345acb0e7f9da894a3443: {
        path: '/actual_payment_records',
        name: 'actual_payment_records',
        title: '实缴记录',
        component: () =>
            import(
                '@/views/finance-management/payable-management/actual-payment-records'
            ),
        meta: {
            btnRights: {
                'actualPayment-update-options': 'Collect/Update[编辑实缴]',
                'actualPayment-del-options': '.Collect/Delete[删除实缴]',
                'actualPayment-confirm-batch': '.Collect/confirmBatch[批量确认]'
            }
        }
    },
    // 财务管理-应缴管理-支出查询
    '8c40ae5e545545449d1caa7a050cab56': {
        path: '/expense_query',
        name: 'expense_query',
        title: '支出查询',
        component: () =>
            import(
                '@/views/finance-management/payable-management/expense-query'
            ),
        meta: {
            btnRights: {
                'expenseQuery-del-options': 'Expend/Delete[删除]',
                'expenseQuery-confirm-batch-options':
                    'Expend/ConfirmBatch[批量确认]',
                'expenseQuery-confirm-options': 'Expend/Confirm[确认]',
                'enterClass-update-table':
                    'student/updateEnterClass:put[编辑报考班型]',
                'enterClass-add-table':
                    'student/updateEnterClass:post[添加报考班型]',
                'card-update-table': 'student/updateMakeCard:put[编辑发卡驾校]',
                'card-add-table': 'student/updateMakeCard:post[添加发卡驾校]'
            }
        }
    },
    //小程序管理 -章节
    '4efe180673c9457e845cb6fadfc7266d': {
        path: '/section_management',
        name: 'section_management',
        title: '章节管理',
        component: () => import('@/views/wx-management/section-management')
    },
    // //小程序管理 -图标
    b2796ac1ae10486fbdac317ec545926b: {
        path: '/icon_management',
        name: 'icon_management',
        title: '图标管理',
        component: () => import('@/views/wx-management/icon-management')
    },
    // //小程序管理 -图标
    '86af9392f5304f5ea746f1885be202b0': {
        path: '/wxcoach_management',
        name: 'wxcoach_management',
        title: '展示教练',
        component: () => import('@/views/wx-management/wxcoach-management')
    },
    //小程序管理 -图标类型
    '6b02ab21fc3842389a4527deab0bcb84': {
        path: '/iconcategory_management',
        name: 'iconcategory_management',
        title: '图标类型',
        component: () => import('@/views/wx-management/iconcategory-management')
    },
    // //小程序管理 -轮播图
    ccd9c820b8e240fe92ec69951eb03c6e: {
        path: '/carousel_management',
        name: 'carousel_management',
        title: '轮播图',
        component: () => import('@/views/wx-management/carousel-management')
    },
    // //小程序管理 -专项分类
    dd36bb7e61a740cea093d37b7134b452: {
        path: '/specialcode_management',
        name: 'specialcode_management',
        title: '专项分类',
        component: () =>
            import('@/views/parameter-setting/specialcode-management')
    },

    // //小程序管理 -资讯分类
    '7374213a2f3b4fa9808c6d083062acb3': {
        path: '/newscode_management',
        name: 'newscode_management',
        title: '资讯分类',
        component: () => import('@/views/parameter-setting/newscode-management')
    },

    //小程序管理 -文章管理
    '0974a19cb8a14a4284299aa5a2e9247f': {
        path: '/article_management',
        name: 'article_management',
        title: '文章管理',
        component: () => import('@/views/wx-management/article-management')
    },

    // //小程序管理 -考题管理
    '86e5bea2b7a4443984c873c3ef2b3e1b': {
        path: '/subject_management',
        name: 'subject_management',
        title: 'C考题',
        component: () => import('@/views/wx-management/subject-management'),
        meta: {
            btnRights: {
                'special-update-table': 'Subject/UpdateSpecialCode[编辑专项]',
                'special-add-table': 'Subject/UpdateSpecialCode[添加专项]'
            }
        }
    },
    '2321ee9311d847ceb99704dab817a41e': {
        path: '/subject_management2',
        name: 'subject_management2',
        title: 'ED考题',
        component: () => import('@/views/wx-management/subject-management2'),
        meta: {
            btnRights: {
                'special-update-table': 'Subject/UpdateSpecialCode[编辑专项]',
                'special-add-table': 'Subject/UpdateSpecialCode[添加专项]'
            }
        }
    },
    // //小程序管理 -微信用户管理
    e3e9ce382b154ad5b0b7ae27cdfb89f0: {
        path: '/wechantuser_management',
        name: 'wechantuser_management',
        title: '微信用户管理',
        component: () => import('@/views/wx-management/wechantuser-management')
    },
    '3ecab594e20b45db87f6da981304edf3': {
        path: '/answer_user',
        name: 'answer_user',
        title: '用户列表',
        component: () => import('@/views/wx-management/answer-user')
    },
    feebd562882348ad9040b8eae7997127: {
        path: '/answer_record',
        name: 'answer_record',
        title: '答题记录',
        component: () => import('@/views/wx-management/answer-record')
    }
    // //小程序管理 -模拟考试管理
    // '9f7ae1c9f4b44f9db5ccf379f80dcf7e': {
    //     path: '/imitate_management',
    //     name: 'imitate_management',
    //     title: '模拟考试管理',
    //     component: () => import('@/views/wx-management/imitate-management')
    // },
}
