export const education = {
    1: '小学',
    2: '初中',
    3: '高中',
    4: '中专',
    5: '大专',
    6: '本科',
    7: '硕士',
    8: '博士',
}

//是否
export const tOrfNumm = {
    1: '是',
    0: '否',
}
//是否
export const tOrf = {
    1: '是',
    2: '否',
}
export const pass={
    0:'未通过',
    1:'通过'
}
// 性别
export const genderCode = {
    0: '未知',
    1: '男',
    2: '女'
}

// 生源
export const from = {
    0: '未知',
    1: '男',
    2: '女'
}

// 学员状态（毕业状态）
export const studentState = {
    0: '未报班',
    1: '科目一',
    2: '科目二',
    3: '科目三',
    4: '科目四',
    5: '已毕业',
    6: '申请退学',
    7: '已退学',
    8: '退学中'
}
// 在库状态
export const studentStock = {
    0: '未报班=-1',
    1: '科一正考=0',
    2: '科一补考=7',
    3: '科二正考=1',
    4: '科二补考=2',
    5: '科三正考=3',
    6: '科三补考=4',
    7: '科四正考=5',
    8: '科四补考=8',
    9: '已毕业=6',
    10: '退学中=9',
    11: '已退学=10',
    12: '仅二正考=20',
    13: '二正三补=21',
    14:'仅二补考=22',
    15: '二三补考=23',
    sort: true
}
// 过期列表-在库状态
export const overDue = {
    3: '科二正考=1',
    4: '科二补考=2',
    5: '科三正考=3',
    6: '科三补考=4',
    12: '仅二正考=20',
    13: '二正三补=21',
    14:'仅二补考=22',
    15: '二三补考=23',
    sort: true
}
// 科二三列表在库状态
export const fourLib = {
    7: '科四正考=5',
    8: '科四补考=8',
    sort: true
}
// 科目状态
export const themeState = {
    1: '不合格',
    2: '合格',
    3: '缺考',
    //4: '预约中',
    5: '预约成功',
    6: '预约失败',
    7: '取消预约',
    8: '预约成功取消预约'
}

// 生源
export const studentFrom = {
    0: '未知',
    1: '在校',
    2: '非在校',
}

// 科一学时
export const oneCode = {
    0: '空白',
    1: '运管审核完成',
    2: '未完成',
    3: '考核员待签字',
    4: '学员待签字',
    5: '教练待签字',
    6: '驾校待签章',
    7: '驾校待上报',
    8: '运管待审核',
    9: '运管驳回',
}

// 科一学时
export const theoneCode = [
    {
        value: 0,
        label: '空白'
    },
    {
        value: 1,
        label: '运管审核完成'
    },
    {
        value: 2,
        label: '未完成'
    },
    {
        value: 3,
        label: '考核员待签字'
    },
    {
        value: 4,
        label: '学员待签字'
    },
    {
        value: 5,
        label: '教练待签字'
    },
    {
        value: 6,
        label: '驾校待签章'
    },
    {
        value: 7,
        label: '驾校待上报'
    },
    {
        value: 8,
        label: '运管待审核'
    },
    {
        value: 9,
        label: '运管驳回'
    },
]

// 报班状态
export const enteredCode = {
    0: '未报班及异常',
    1: '已报班',
    2 : '未报班',
    3 : '报班异常',
}

// 退学类型
export const dropCode = {
    0: '自愿放弃报考驾驶证（客诉）',
    1: '自愿放弃报考驾驶证（非客诉）',
    2: '异地转出申请退学',
    3: '单科目五次不过自动退学',
    4: '三年过期自动退学',
    5: '其他原因',
}

// 报名种类
export const enteredType = {
    0: '普通报考',
    1: '科目报考二三',
    2: '科目报考二',
    3: '科目报考三',
}

//考试状态
export const examStatu ={
    1:'不合格',
    2:'合格',
    3:'缺考',
    4:'预约中',
    5:'预约成功',
    6:'预约失败',
    7:'取消预约',
    8: '预约成功取消预约'
}

// 科目种类
export const themeExamState = {
    1: '科目一',
    2: '科目二',
    3: '科目三',
    4: '科目四',
    5: '结业'
}
// 科目种类
export const carousleCode = {
    1: '科目一',
    4: '科目四',
    8: '推荐'
}

// 油耗管理-加油方式
export const addOilType = {
    0: '油卡加油',
    1: '报销加油'
}
export const oliCode={
    0:'油耗正常',
    1:'油耗异常',
}
export const oliOption={
    92:'92',
    95:'95',
    98:'98',
    0:'0'
}
export  const errCode={
    0:'非异常',
    1:'未处理',
    2:'已处理',
}
// 车辆管理-车辆状态
export const carStatusCode = {
    0: '使用中',
    1: '未分配',
    2: '已停用',
    3: '已报废',
}
// 油卡配置-使用状态
export const oilCardStatusCode = {
    0: '使用中',
    1: '已停用',
    2: '已注销',
}

export const insuranceStatusCode = {
    0: '正常',
    2: '即将过期',
    3: '已过期',
}

// 车辆管理-使用性质
export const businessCodeStatusCode = {
    0: '非营运',
    1: '营运教练车',
    3: '其他',
}

// 考前管理-已报班列表
export const materialCode = {
    1: '已齐全=0',
    0: '未齐全=1',
    2: '材料待上报=2',
    3: '材料已上报=3',
    sort: true
}
// 考前管理-材料类型
export const materialType= {
    0: '复印件',
    1: '申请表',
    2: '体检单',
    3: '照片',
}

// 车辆管理-车辆使用途径
export const carUseCode = {
    0: '科二训练',
    1: '科三训练',
    2: '混合训练',
    3: '接送使用',
    4: '销售使用',
    5: '名额空置',
    6: '其他用途',
}

// 车辆管理-车辆能源种类
export const energyCode = {
    0: '汽油',
    1: '柴油',
    2: '纯电动',
    3: '混动',
}

// 车辆管理-存在方式
export const existCode = {
    0: '自有',
    1: '租赁',
    2: '挂靠',
    3: '其他',
}

export const payStatus = {
    0: '未缴费',
    1: '预报名',
    2: '分期',
    3: '全额',
}

export const registerMap = {
    '姓名': 'name',
    '身份证号': 'idCard',
    '身份证地址': 'address',
    '居住地址': 'realAddress',
    '联系电话': 'mobile',
    '报名日期': 'enteredDate',
    '报名种类': ['classTypeData', 'label', 'enteredCode'],
    '培训校区': ['trainData', 'label', 'trainAreaId'],
    '报考车型': ['carObj', 'value', 'certificateId'],
    '报考班型': ['classObj', 'name', 'enterClassId'],
    '学费总价': 'money',
    '招生人': ['refeData', 'label', 'referenceId'],
    '报名点':['pointData','value','enteredPointId'],
    '是否增驾':['tOrf','label','isUpCertificate'],
    // '推荐部门': ['departmentData', 'label', 'departmentId'],
    '现准驾车型':['carData','value','nowCertificateId'],
    '现驾驶证截止日期':'nowCertificateEndTime',
    '现驾驶证档案号':'nowCertificateNo',
    '生源状况': ['stausOption', 'label', 'from'],
    '院校': ['schoolData', 'value', 'areaCode'],
    '年级':['nowTime', 'label', 'schoolAge']

}

// 提醒方式
export const remindType = {
    0: '短信'
}

//发放状态
export const referType = {
    0: '未发放',
    1: '待发放',
    2: '已发放',
}


// 确定状态
export const isConfirm = {
    0: '未确认',
    1: '已确认'
}

// 支付人
export const payEr = {
    0: '本人支付',
    1: '教练或招生人',
    2: '非本人支付'
}
// 教练组-教练组类型
export const coachOption = {
    0:'单人单车',
    1:'单人两车',
    2:'两人两车',
    3:'两人三车',
    4:'两人四车'
}

// 科二三 - 显示内容
export const showContent = {
    2:'科二未通过',
    3:'科二已通过'
}
// 油耗记录-跟踪记录
export const flowerMsg = {
    0: '非异常',
    1: '未处理',
    2: '已处理'
}

// 油耗记录-跟踪记录
export const baoxiaoCode = {
    0: '报销审核中',
    1: '报销中',
    2: '报销异常',
    3: '已报销'
}

// 油耗记录-油耗状态
export const oilCode = {
    0: '油耗正常',
    1: '油耗异常'
}

// 人事管理-教练员管理-离职状态
export const workState = {
    0: '在职',
    1: '已离职',
    '-1': '离职中'
}

// 人事管理-教练员管理-岗位类型
export const workCode = {
    1: '内部',
    2: '外聘',
    3: '挂靠',
}

// 人事管理-教练列表-证持有码
export const certificateCode = {
    1: '已持有',
    2: '办理中',
    3: '未持有',
}

// 资产管理 油耗记录
export const oilError = {
    1: '未处理',
    2: '已处理',
}

// 缴费异常配置

export const studentStocks = {
    '-1': '未报班',
    0: '科一正考',
    1: '科二正考',
    2: '科二补考',
    3: '科三正考',
    4: '科三补考',
    5: '科四正考',
    6: '已毕业',
    7: '科一补考',
    8: '科四补考',
    9: '退学中',
    10: '已退学',
    20: '仅二正考',
    21: '二正补考',
    22:'仅二补考',
    23: '二三补考',
}

// 培训管理 - 科一列表
export const themeOne = {
    1: '科一正考=0',
    2: '科一补考=7',
    0: '未报班=-1',
    sort: true
}

// 考试管理-预约管理-抓取异常
export const errorResCode = {
    0: '预约成功查询',
    1: '成绩查询',
    2: '取消预约查询',
}

// 财务管理-财务设置-考试缴费金额
export const certificateType = {
    0: '常规C证',
    1: '其他C证件',
    2: '摩托车证',
    3: 'B类证件',
    4: 'A类证件',
}
//查询类型
export const examTaskCode={
    0:'预约成功查询',
    1:'成绩查询',
    2:'取消预约查询'
}

// 客诉跟踪-客诉状态
export const feedbackCode ={
    0: '空白',
    1: '客诉未解决',
    2: '客诉已解决'
}
//答题选项类别
export  const subjectCode={
    0:'文字',
    1:'图片',
    2:'动画',
}
//答题选项类别
export  const optionCode={
    0:'单选',
    1:'多选',
    2:'判断',
}
//难度类别
export  const difficultyCode={
    0:'普通',
    1:'易错、难题、争议',
}
//训练类别
export  const trainCode={
    0:'顺序',
    1:'随机',
    2:'全真模拟',
}

// 费用类别
export const costType = {
    805: '新增管理费',
    806: '新增委培费',
    807: '新增营销费',
    808: '新增其他支出'
}
// 支出类别
export const revenueExpendCode = {
    0: '收入',
    1: '支出'

}


export const refType = {
    805: 'ManagementMoneyDialogRef',
    806: 'EntrustMoneyDialogRef',
    807: 'MarketingMoneyDialogRef',
    808: 'OtherMoneyDialogRef'
}