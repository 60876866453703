import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui'
import store from '@/store'
import cloneDeep from 'lodash/cloneDeep'
import { asyncRoute } from '@/utils/ROUTE'
Vue.use(VueRouter)
const routes = [
	{
		path: '/',
		component: () => import('@/components/Layout'),
		redirect: '/about',
		children: [
			{
				path: '/about',
				name: 'about',
				component: () => import('@/views/About.vue'),
			}
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login.vue')
	},
    {
        path: '/upgrade_app',
        name: 'upgrade_app',
        component: () => import('@/views/parameter-setting/upgrade-app'),
    }
]

function createRouter () {
	return new VueRouter({
		mode: 'history',
		routes
	})
}
VueRouter.$addRoutes = (params) => {
	router.matcher = new VueRouter({mode: 'history'}).matcher
	router.addRoutes(params)
}
const router = createRouter()

export function initRouter (isLogin = false)  {
	let currentRouter = store.state.router
	router.options.routes = cloneDeep(routes)
    // const btnRights = JSON.parse(localStorage.rights ?? '{}')
    const _pushRouter = (route) => {
        let routerList = route ?? currentRouter
        for (const item of routerList) {
            if (item?.children?.length) _pushRouter(item.children)
            else if (item.id in asyncRoute) {
                asyncRoute[item.id].meta = {
                    ...(asyncRoute[item.id]?.meta || {}),
                    pageId: item.id
                }
                router.options.routes[0].children.push(asyncRoute[item.id])
            }
        }
    }
    _pushRouter(currentRouter)
	router.options.routes.push({
		path: '*',
		name: 'notFound404',
		component: () => import('@/views/not-found.vue')
	})
	// router.matcher = createRouter().matcher
	VueRouter.$addRoutes(router.options.routes)
    isLogin && router.replace('/')
}

router.beforeEach((to, form, next) => {
	if (to.fullPath.includes('/login'))  return next()
	if (!localStorage.token) {
		Message({
            type: 'error',
            message: '您的身份信息有误，请重新登录！',
            duration: 5000,
            showClose: true
        })
		return next({ name: 'login', replace: true })
	}
	if (to?.meta?.pageId) {
        const nowRights = JSON.parse(localStorage.rights || '0')
        store.commit('SET_POWER', nowRights === -1 ? -1 : (nowRights[to.meta.pageId] || {}))
    }
	next()
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
export default router
