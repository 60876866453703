import * as dataSource from '@/utils/back'

export const SelectObj = {
    PayState: [dataSource.getPayList, 'jiaofei'],
    5: [dataSource.getCarList, 'chexing'],
    DriverShop: [dataSource.getDriverList, 'jiaxiao'],
    User: [
        dataSource.getCoachList,//教练
        dataSource.getReferencList,//招生人
        dataSource.getAllUserList//用户
    ],
    TrainArea: [dataSource.getTrainAreaList, 'xiaoqu'],
    29: [dataSource.getPointList, 'baomingdian'],
    30: [dataSource.getBankList, 'yinhang'],
    28: [dataSource.getDeductionList, 'koukuan'],
    101: [dataSource.getHospitalList, 'yiyuan'],
    6: [dataSource.getMoneyList, 'shoukuanzhanghu'],
    ExamInsure: [dataSource.getupExamtList, 'bukaobaoxian'],
    EnterClass: [dataSource.getClassList, 'baokaobanxing'],
    ExamPlace: [dataSource.getExamPlaceList, 'kaoshichangdi'],
    3: [dataSource.getpaylList, 'zhifu'],
    OilCard: [dataSource.getAllOilCard, 'youka'],
    Car: [dataSource.getAllCard, 'cheliang'],
    31: [dataSource.getCountryList, 'guojia'],
    11: [dataSource.getAllAddOilPointList, 'jiayoudian'],
    10: [dataSource.getRepairList, 'weixiudian'],
    23: [dataSource.getColorList, 'yanse'],
    Role: [dataSource.getAllRole, 'juese'],
    80: [dataSource.getOppositeList, 'opposite'],
    81: [dataSource.getClueFromList, 'cluefrom'],
    14: [dataSource.getSpecialCodeList, 'specialcode'],
    15: [dataSource.getNewsCodeList, 'newscode'],
    Section: [dataSource.getSectionList, 'section'],
    Icon: [dataSource.getIconList, 'icon']
}