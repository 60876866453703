<template>
  <router-view />
</template>

<script type="text/javascript" src="https://webapi.amap.com/maps?v=1.4.4&key=5d8ca8519aea42f9c16f7d94ca556e5c"></script>
<script>

import { initRouter } from './router'
export default {
    created () {
        initRouter()
    }
}
</script>
