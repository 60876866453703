<template>
    <el-dropdown
        trigger="click"
        :hide-on-click="false"
    >
        <i class="iconfont iconshaixuan icon-style" title="过滤表格列"></i>
        <el-dropdown-menu slot="dropdown">
            <div class="options">
                <el-button
                    size="mini"
                    type="text"
                    @click.native="selectAll"
                    title="全部选中"
                >
                    全选
                </el-button>
            </div>
            <el-dropdown-item
                v-for="item in Object.values(this.columnList)"
                :key="item.label"
            >
                <el-checkbox
                    :label="item.label"
                    v-model="item.show"
                    @change="handleChange"
                />
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
export default {
    props: {
        columnList: {
            type: Object,
            default: () => ({})
        }
    },
    name: 'my-filter-column',
    data () {
        return {
            getColumnList: this.columnList
        }
    },
    methods: {
        handleChange () {},
        selectAll () {}
    },
    mounted () {
        let filterCol = JSON.parse(localStorage.filterColumn)
        let path = this.$route.path
        if (!this._.isEmpty(filterCol[path])) this.getColumnList = filterCol[path]
        this.handleChange = this._.debounce(() => {
            filterCol[path] = this.columnList
            localStorage.filterColumn = JSON.stringify(filterCol)
        }, 250)
        this.selectAll = this._.debounce(() => {
            for (let key in this.columnList) {
                if (this.columnList[key].show) continue
                this.columnList[key].show = true
            }
        }, 250)
    }
}
</script>

<style scoped lang="scss">
.options{
    margin-top: -8px;
    display: flex;
    justify-content: center;
}
</style>